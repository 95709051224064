import { MobXProviderContext } from 'mobx-react'
import React from 'react'

import UserStore from '../../user/UserStore'
import PlatformsStore from '../../platforms/PlatformsStore'
import CompaniesStore from '../../companies/CompaniesStore'
import AuthStore from '../../auth/AuthStore'
import DeviceStore from '../../device/DeviceStore'
import NavigationStore from '../../navigation/NavigationStore'
import ApplicationStore from '../../apps/ApplicationStore'
import PlaylistsStore from '../../playlists/PlaylistsStore'
import StyngsStore from '../../styngs/StyngsStore'
import NftsStore from '../../nfts/NftsStore'
import StynglistsStore from '../../stynglists/StynglistsStore'
import SoundboardStore from '../../soundboard/SoundboardStore'
import EditStyngStore from '../../styngs/CreateStyng/CreateStyngStore'
import TracksInPlaylistStore from '../../playlists/pages/EditPlaylist/TracksInPlaylistStore'
import RadioAdsStore from '../../radioAds/RadioAdsStore'
import AdGroupsStore from '../../radioAdGroups/AdGroupsStore'
import TerritoriesStore from '../../territories/TerritoriesStore'
import ReportsStore from '../../reporting/ReportingStore'
import NotificationsStore from '../../notifications/NotificationsStore'
import ActivityLogStore from '../../activityLog/ActivityLogStore'

export interface RootStore {
  authStore: AuthStore
  applicationStore: ApplicationStore
  userStore: UserStore
  platformsStore: PlatformsStore
  companiesStore: CompaniesStore
  playlistsStore: PlaylistsStore
  styngsStore: StyngsStore
  nftsStore: NftsStore
  stynglistsStore: StynglistsStore
  soundboardStore: SoundboardStore
  editStyngStore: EditStyngStore
  deviceStore: DeviceStore
  navigationStore: NavigationStore
  tracksInPlaylistStore: TracksInPlaylistStore
  radioAdsStore: RadioAdsStore
  adGroupsStore: AdGroupsStore
  territoriesStore: TerritoriesStore
  reportsStore: ReportsStore
  notificationsStore: NotificationsStore
  activityLogStore: ActivityLogStore
}

const useStores = () => {
  return React.useContext(MobXProviderContext) as RootStore
}

export default useStores
