import { useQuery } from 'react-query'
import * as R from 'ramda'

import API from '../common/api'
import useStores from '../common/hook/useStore'
import { RadioAdGroupResponse } from '../common/api/radioAdGroups/radioAdGroups'

export const useAdGroupsListQuery = () => {
  const { adGroupsStore } = useStores()

  const { pageSize, page } = adGroupsStore

  return useQuery<RadioAdGroupResponse>(
    ['adGroupList', pageSize, page],
    () => {
      return API.playlist.adGroupList({
        size: pageSize,
        page: page,
      })
    },
    {
      retry: 1,
      keepPreviousData: true,
    },
  )
}

export const useAdGroupQuery = (adGroupParam: Nullable<string> = null) => {
  const { adGroupsStore } = useStores()
  const { adGroupId: adGroupStore } = adGroupsStore
  const playlistId = adGroupParam ?? adGroupStore

  return useQuery<any>(
    ['adGroup', playlistId],
    () => {
      return API.playlist.getAdGroup({
        playlistId: playlistId,
      })
    },
    {
      enabled: !R.isEmpty(playlistId),
      keepPreviousData: true,
      retry: 1,
    },
  )
}
