/* eslint-disable quotes */
import React, { useState } from 'react'
import Modal from '@mui/material/Modal'
import Box from '@mui/material/Box'
import Button, { ButtonColor, ButtonVariant } from '../../ui/Button/Button'
import Text from '../../locale/strings'
import TextField from '../../ui/TextField/TextField'
import { CSVLink } from 'react-csv'

import styles from './csvModal.module.scss'

interface ModalProps {
  open: boolean
  dataTable: any
  columns: any
  handleClose: () => void
}

const CsvModal: React.FC<ModalProps> = ({ open, dataTable, columns, handleClose }: ModalProps) => {
  const [fileName, setFileName] = useState<string>('')
  const [error, setError] = useState<boolean>(false)

  const handleChange = (value: string) => {
    if (value.length <= 151) {
      setError(false)
    }

    if (value.length >= 151) {
      setError(true)

      return
    }

    setFileName(value)
  }

  const csvReport = {
    data: dataTable,
    headers: columns,
    filename: `${fileName}.csv`,
  }

  return (
    <div className={styles.holder}>
      <Modal open={open} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
        <React.Fragment>
          <Box>
            <div className={styles.container}>
              <div className={styles.header}>{'Download CSV'}</div>
              <div className={styles.inputHolder}>
                <label className={styles.label}>File name:</label>
                <TextField
                  size="small"
                  value={fileName}
                  error={error ? Text.errors.maxLength150 : undefined}
                  onChange={(e) => handleChange(e.target.value)}
                />
              </div>
              <div className={styles.submitContainer}>
                <Button variant={ButtonVariant.OUTLINED} onClick={() => handleClose()}>
                  {Text.common.cancel}
                </Button>
                <CSVLink
                  {...csvReport}
                  enclosingCharacter={``}
                  className={styles.csvButton}
                  onClick={() => {
                    handleClose()
                  }}
                >
                  <Button disabled={fileName === ''} color={fileName === '' ? ButtonColor.INFO : ButtonColor.PRIMARY}>
                    {Text.common.save}
                  </Button>
                </CSVLink>
              </div>
            </div>
          </Box>
        </React.Fragment>
      </Modal>
    </div>
  )
}

export default CsvModal
