import React from 'react'
import { observer } from 'mobx-react'
import { thread } from 'lib/fn/thread'
import { useStyngsQuery } from '../StyngsStore'

import Styngs from '../../styngs/Styngs'
import LoadingRemote from '../../common/LoadingRemote'

const StyngsPage: React.FC = () => {
  const { data, refetch, status } = useStyngsQuery()

  const styngs = data?.items ?? []
  const pagesCount = data?.pagesCount ?? 1

  return (
    <LoadingRemote status={status}>
      <Styngs styngs={styngs} pagesCount={pagesCount} refetch={refetch} />
    </LoadingRemote>
  )
}

export default thread(StyngsPage, [observer])
