import { useState } from 'react'
import { Redirect } from 'react-router-dom'
import { useFormik } from 'formik'
import { observer } from 'mobx-react'
import * as R from 'ramda'
import * as yup from 'yup'

// import { MainRoute } from 'main'
import { UsersRoute } from './../../../users'
import Button from '../../../ui/Button/Button'
import Container from '../../../ui/Container/Container'
import TextField from '../../../ui/TextField/TextField'
import Storage from '../../../common/storage'
import useStores from '../../../common/hook/useStore'
import PasswordEye from '../../../ui/PasswordEye/PasswordEye'
import locale from '../../../locale/strings'

import styles from './loginPage.module.scss'
import ErrorText from '../../../ui/ErrorText/ErrorText'
import { useMutation } from 'react-query'
import { useHistory } from 'react-router-dom'
// import { EditStyngRequest, EditStyngResponse } from '../../../common/api/styng/styng'
import API from '../../../common/api'
// import logger from 'lib/logger'
// import { StyngsRoute } from '../../../styngs/pages'
import { LoginRequest, LoginResponse } from '../../../common/api/auth/login'
import { ApiError } from 'lib/api/error'
import { ErrorCode } from '../../../common/errors'
import jwt_decode from 'jwt-decode'
import { LoginRoute } from '../../../auth/pages/Login'

const schema = yup.object({
  email: yup.string().email(locale.errors.emailInvalid).required('Email is required'),
  password: yup.string().required('Password is required'),
  // .matches(/^[A-Za-z\d]{8,}$/, locale.errors.passwordInvalid),
})

interface FormikValues {
  email: string
  password: string
}

const LoginPage = () => {
  const { deviceStore, authStore } = useStores()
  const [visiblePass, setVisiblePass] = useState(false)
  const [error, setError] = useState('')
  const history = useHistory()

  // const mutationLogin = useMutation<LoginResponse, ApiError, LoginRequest>(
  //   (body) => {
  //     return API.user.login(body)
  //   },
  //   {
  //     onSuccess: (data) => {
  //       authStore.login(data)
  //       handleToken(jwt_decode(data?.credentials?.accessToken))

  //       Storage.delete('adminMode')
  //     },

  //     onError: (err) => {
  //       if (err?.errorCode === ErrorCode.LOGIN_NOT_VALID) {
  //         setError(err.errorMessage ?? '')
  //       } else {
  //         setError(err.errorViolations ?? '')
  //       }
  //     },
  //   },
  // )

  const mutationLogin = useMutation<LoginResponse, ApiError, LoginRequest>(
    (body) => {
      return API.user.login(body)
    },
    {
      onSuccess: (data) => {
        authStore.login(data)
        handleToken(jwt_decode(data?.credentials?.accessToken))

        Storage.delete('adminMode')
      },
      onError: (err) => {
        if (err?.errorCode === ErrorCode.LOGIN_NOT_VALID) {
          setError(locale.errors.incorrectValues)
        } else {
          setError(locale.errors.something)
        }
      },
    },
  )

  const handleToken = (decoded: any) => {
    var tokenExpiration = new Date(decoded.exp * 1000).getTime()

    setInterval(async () => {
      if (tokenExpiration <= new Date().getTime()) {
        var refreshToken = Storage.get('refreshToken')

        try {
          const result = await API.token.refresh({ refreshToken })

          authStore.handleTokenResult(result)

          var newAccessTokenJwt: any = jwt_decode(result?.credentials?.accessToken)
          tokenExpiration = new Date(newAccessTokenJwt.exp * 1000).getTime()
        } catch (e) {
          history.push(LoginRoute.path)
        }
      }
    }, 1000)
  }

  const handleLogin = async ({ email, password }: FormikValues) => {
    await mutationLogin.mutate({
      email,
      password,
      deviceId: deviceStore.deviceId,
    })
  }

  const formik = useFormik<FormikValues>({
    initialValues: {
      email: '',
      password: '',
    },
    validationSchema: schema,
    validateOnChange: false,
    validateOnBlur: false,
    onSubmit: handleLogin,
  })

  if (authStore.isAuthenticated) {
    return <Redirect to={UsersRoute.path} />
  }

  const handleClickVisible = () => {
    setVisiblePass(R.not)
  }

  return (
    <Container className={styles.container} maxWidth="xs">
      <form className={styles.form} onSubmit={formik.handleSubmit}>
        <div className={styles.logo} />
        <h2 className={styles.headerText}>{locale.common.signIn}</h2>
        <TextField
          fullWidth
          id="email"
          label="Email Address"
          name="email"
          autoComplete="email"
          value={formik.values.email}
          error={formik.errors.email}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
        />
        <TextField
          fullWidth
          name="password"
          label="Password"
          type={visiblePass ? 'text' : 'password'}
          id="password"
          autoComplete="current-password"
          value={formik.values.password}
          error={formik.errors.password}
          adornment={<PasswordEye visible={visiblePass} onClick={handleClickVisible} />}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
        />
        <ErrorText error={error} />
        <Button type="submit" className={styles.button} loading={mutationLogin.isLoading}>
          {locale.common.signIn}
        </Button>
      </form>
    </Container>
  )
}

export default observer(LoginPage)
