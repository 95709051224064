import { FormikErrors } from 'formik'
import { Soundboard, SoundboardGeneral } from 'common/api/soundboard/soundboard'

import useStores from 'common/hook/useStore'

import { SoundboardRoute } from 'soundboard'

import useImage from 'common/hook/useImage'
import { defaultImg } from 'common/constants'

import TextField from 'ui/TextField/TextField'
import Button, { ButtonVariant } from '../../../ui/Button/Button'

import Text from '../../../locale/strings'

import styles from './General.module.scss'

interface GeneralProps {
  values: SoundboardGeneral
  errors: FormikErrors<Soundboard>
  handleChange: (e: React.ChangeEvent<any>) => void
  handleSoundboardImageUpload: (event: React.ChangeEvent<HTMLInputElement>) => void
  editFlow?: boolean
  handleSubmitGeneralInfo?: () => void
}

const General = ({
  editFlow,
  values,
  errors,
  handleSubmitGeneralInfo,
  handleChange,
  handleSoundboardImageUpload,
}: GeneralProps) => {
  const { navigationStore } = useStores()

  const { data } = useImage(values.imageId ?? '')
  const imageSoundboard = data?.url ?? defaultImg

  return (
    <div className={styles.infoWrapper}>
      <div className={styles.infoFields}>
        <TextField
          fullWidth
          data-test="name-field"
          label={Text.fields.name + ' *'}
          name="name"
          value={values.name}
          error={errors.name}
          inputProps={{ maxLength: 50 }}
          onChange={handleChange}
        />
        <TextField
          fullWidth
          data-test="subscription-price-field"
          type="number"
          label={'Subscription price'}
          name="subscriptionPrice"
          value={values.subscriptionPrice}
          InputProps={{
            inputProps: {
              min: 0,
            },
          }}
          onKeyPress={(evt: any) => ['e', 'E', '+', '-'].includes(evt.key) && evt.preventDefault()}
          onChange={handleChange}
        />
        <TextField
          fullWidth
          data-test="styng-price-field"
          type="number"
          label={'Styng price'}
          name="styngPrice"
          value={values.styngPrice}
          InputProps={{
            inputProps: {
              min: 0,
            },
          }}
          onKeyPress={(evt: any) => ['e', 'E', '+', '-'].includes(evt.key) && evt.preventDefault()}
          onChange={handleChange}
        />
        <TextField
          disabled
          fullWidth
          data-test="source-type-field"
          label={'Source type'}
          name="sourceType"
          value={values.sourceType}
          onChange={handleChange}
        />
      </div>
      <div className={styles.uploadImage}>
        <img src={imageSoundboard} alt="soundboardPicture" loading="lazy" />
        <label htmlFor="file-input-id">
          <input
            hidden
            id="file-input-id"
            type="file"
            accept="image/*"
            value=""
            onChange={handleSoundboardImageUpload}
          />
          <Button isUpload data-test="upload-image-button" className={styles.uploadButton}>
            {Text.page.styngs.create.uploadCover}
          </Button>
        </label>
      </div>
      {editFlow && (
        <div className={styles.submitContainer}>
          <Button
            data-test="cancel-button"
            variant={ButtonVariant.OUTLINED}
            onClick={() => navigationStore.goToPage(SoundboardRoute.path)}
          >
            {Text.common.cancel}
          </Button>
          <Button data-test="submit-button" className={styles.saveButton} onClick={handleSubmitGeneralInfo}>
            {Text.common.save}
          </Button>
        </div>
      )}
    </div>
  )
}

export default General
