import useStores from '../common/hook/useStore'
import { useQuery } from 'react-query'
import API from '../common/api'
import { StyngsResponse } from '../common/api/styng/styng'
import PaginationStore from '../common/PaginationStore'

class StyngsStore extends PaginationStore {}

export const useStyngsQuery = () => {
  const { styngsStore } = useStores()

  const { pageSize, page, filter } = styngsStore

  return useQuery<StyngsResponse>(
    ['styngs', pageSize, page, filter],
    () => {
      return API.styngs.list({
        page,
        size: pageSize,
        name: filter,
      })
    },
    {
      retry: 3,
      keepPreviousData: true,
    },
  )
}

export default StyngsStore
