import { Box, Autocomplete, Checkbox, Chip, FormControl, Switch, TextField as MuiTextField } from '@mui/material'

import { Application } from 'common/api/app/app'

import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank'
import CheckBoxIcon from '@mui/icons-material/CheckBox'

import styles from './BindApplications.module.scss'

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />
const checkedIcon = <CheckBoxIcon fontSize="small" />

interface BindApplicationsProps {
  allApps: Application[]
  selectedApps: Application[]
  handleChangeApps: (event: React.SyntheticEvent, value: any) => void
  handleDeleteApp: (optionId: string) => void
  withPublish?: boolean
  handleAppPublishToggle?: (app: any) => void
}

const BindApplications = ({
  allApps,
  selectedApps,
  handleChangeApps,
  handleDeleteApp,
  withPublish,
  handleAppPublishToggle,
}: BindApplicationsProps) => {
  return (
    <FormControl fullWidth className={`${withPublish ? styles.autocompleteWithSwitch : styles.customSelect}`}>
      <Autocomplete
        multiple
        disableCloseOnSelect
        data-test="available-apps-field"
        id="availableApps"
        className={styles.autocompleteField}
        isOptionEqualToValue={(option, value) => option.id === value.id}
        value={selectedApps}
        options={allApps}
        getOptionLabel={(option: Application) => option.name}
        renderOption={(props, option, { selected }) => (
          <li {...props} key={option.id}>
            <Checkbox icon={icon} checkedIcon={checkedIcon} style={{ marginRight: 8 }} checked={selected} />
            {option.name}
          </li>
        )}
        renderTags={() => null}
        renderInput={(params) => <MuiTextField {...params} label="Choose applications" placeholder="Search" />}
        onChange={handleChangeApps}
      />
      {selectedApps.length > 0 && (
        <Box className={styles.autocompleteFieldValues}>
          {selectedApps.map((option: any) => (
            <div className={styles.autocompleteFieldWrapper} key={option.id}>
              <Chip
                className={styles.autocompleteFieldValue}
                label={option.name}
                onDelete={() => handleDeleteApp(option.id)}
              />
              {withPublish && (
                <Switch
                  size="small"
                  className={styles.autocompleteFieldSwitch}
                  checked={option.published === true}
                  onChange={handleAppPublishToggle ? () => handleAppPublishToggle(option) : () => {}}
                />
              )}
            </div>
          ))}
        </Box>
      )}
    </FormControl>
  )
}

export default BindApplications
