import PaginationStore from '../common/PaginationStore'
import { observable, action } from 'mobx'

class NotificationsStore extends PaginationStore {
  @observable notifications: any[] = []

  @action
  successNotification(message: string, autoHideDuration: number = 4000) {
    this.notifications.push({ message, type: 'success', autoHideDuration })
  }

  @action
  errorNotification(message: string, autoHideDuration: number = 4000) {
    this.notifications.push({ message, type: 'error', autoHideDuration })
  }

  @action
  infoNotification(message: string, autoHideDuration: number = 4000) {
    this.notifications.push({ message, type: 'info', autoHideDuration })
  }

  @action
  removeNotification(index: number) {
    this.notifications.splice(index, 1)
  }
}

export default NotificationsStore
