import { ApiMethodFactory, HttpMethod } from 'lib/api'
import { LoginRequest, LoginResponse } from './auth/login'
import { RegisterRequest as DeviceRegisterRequest, RegisterResponse as DeviceRegisterResponse } from './device/register'
import { ImageEntity, ImageUrl } from './image/image'
import {
  TrackDownloadResponse,
  TracksResponse,
  AddTrackRequest,
  TracksFromPlaylistResponse,
  TracksFromRoyaltyFreePlaylistResponse,
  DeleteTrackFromPlaylistRequest,
  UpdateTrackExternalMetadataRequest,
  UpdateRoyaltyFreeTrackMetadataRequest,
} from './track/track'
import {
  PlaylistsResponse,
  PlaylistsWithoutAdGroupResponse,
  Playlist,
  AppPlaylistsRequest,
  AppPlaylistsResponse,
  PlaylistDmcaValidationRequest,
  PlaylistDmcaValidationResponse,
  MonetizationTypesResponse,
} from './playlist/playlist'
import {
  EditStyngEntity,
  EditStyngRequest,
  EditStyngResponse,
  StyngsResponse,
  EditNftRequest,
  EditNftResponse,
  NftsResponse,
  TogglePublishNftRequest,
  MintNft,
  Nft,
  UpdateMetadataRequest,
} from './styng/styng'
import {
  EditStynglistEntity,
  EditStynglistResponse,
  StynglistsResponse,
  EditStynglistRequest,
  BindStynglist,
} from './stynglist/stynglist'
import { SoundboardResponse } from './soundboard/soundboard'

import {
  EditUserEntity,
  EditUserRequest,
  EditUserResponse,
  ProfileResponse,
  UserResponse,
  DeleteUser,
} from './user/user'
import { AppEventsResponse, EditAppEventEntity, EditAppEventRequest, EditAppEventResponse } from './appEvent/appEvent'
import {
  EditAppRequest,
  AppsResponse,
  AppInfoEntity,
  DeleteAppProdTypeRequest,
  AddAppProdTypeRequest,
  UpdateAppProdTypeRequest,
  AppPrice,
  AppPricePlan,
  UpdateAppPricePlanRequest,
} from './app/app'
import { CurrenciesResponse, EditCurrencyEntity, EditCurrencyRequest, EditCurrencyResponse } from './currency/currency'
import { Platform, PlatformRequest, PlatformResponse } from './platforms/platforms'
import { CompaniesResponse, EditCompanyEntity, EditCompanyRequest, EditCompanyResponse } from './company/company'
import { GenresResponse } from 'common/api/genre/genre'
import { ArtistsResponse } from 'common/api/artist/artist'
import { AlbumsResponse } from 'common/api/album/album'
import { LabelsResponse } from 'common/api/label/label'
import { LabelOwnersResponse } from 'common/api/labelOwner/labelOwner'
import { TerritoriesResponse } from './territory/territory'
import { ReportsResponse, ReportTypesResponse } from './reporting/reports'

const API_PREFIX = '/api/v1'

export const { make } = new ApiMethodFactory({ apiPrefix: API_PREFIX })

export const getImage = (imageId: string | number) => `/api/v1/cms/files/images/${imageId}`

export { HttpMethod } from 'lib/api'

const API = {
  user: {
    login: make<LoginResponse, LoginRequest>('/auth/users/login', HttpMethod.POST),
    logout: make('/auth/users/logout', HttpMethod.POST),
    profile: make<ProfileResponse>('/cms/users/current', HttpMethod.GET),
    get: make<EditUserEntity>('/cms/users/%(userId)s', HttpMethod.GET, {
      path: ['userId'],
    }),
    delete: make<void>('/cms/users/%(id)s', HttpMethod.DELETE, {
      path: ['id'],
    }),
    create: make<EditUserResponse, EditUserRequest>('/cms/users', HttpMethod.POST),
    put: make<EditUserResponse, EditUserRequest>('/cms/users/%(userId)s', HttpMethod.PUT, {
      path: ['userId'],
    }),
    list: make<UserResponse>('/cms/users', HttpMethod.GET, {
      query: ['page', 'size', 'name', 'id'],
    }),
    update: make<EditUserResponse, EditUserRequest>('/cms/users/%(userId)s', HttpMethod.PUT, {
      path: ['userId'],
    }),
  },
  playlist: {
    list: make<PlaylistsResponse>('/cms/playlists', HttpMethod.GET, {
      query: ['page', 'size', 'name', 'appName', 'published', 'type'],
    }),
    getAll: make<PlaylistsResponse>('/cms/playlists/all', HttpMethod.GET),
    get: make<PlaylistsResponse>('/cms/playlists', HttpMethod.GET, {
      query: ['appId'],
    }),
    getBasicInfo: make<any>('/cms/playlists/%(playlistId)s/basicInfo', HttpMethod.GET, {
      path: ['playlistId'],
    }),
    create: make('/cms/playlists', HttpMethod.POST),
    delete: make('/cms/playlists/%(playlistId)s', HttpMethod.DELETE, {
      path: ['playlistId'],
    }),
    activate: make('/cms/playlists/%(id)s/activate', HttpMethod.POST, {
      path: ['id'],
    }),
    deactivate: make('/cms/playlists/%(id)s/deactivate', HttpMethod.POST, {
      path: ['id'],
    }),
    addTrackToPlaylist: make<Playlist, AddTrackRequest>(
      '/cms/playlists/%(playlistId)s/tracks/%(trackMediaNetId)s',
      HttpMethod.PUT,
      {
        path: ['playlistId', 'trackMediaNetId'],
      },
    ),
    getTracksFromPlaylist: make<TracksFromPlaylistResponse>('/cms/playlists/%(playlistId)s/tracks', HttpMethod.GET, {
      path: ['playlistId'],
      query: ['page', 'size', 'format', 'bitrate'],
    }),
    getTracksFromRoyaltyFreePlaylist: make<TracksFromRoyaltyFreePlaylistResponse>(
      '/cms/playlists/royaltyFree/%(playlistId)s/tracks',
      HttpMethod.GET,
      {
        path: ['playlistId'],
        query: ['page', 'size', 'format', 'bitrate'],
      },
    ),
    deleteTrackFromPlaylist: make<DeleteTrackFromPlaylistRequest>(
      '/cms/playlists/%(playlistId)s/tracks/%(trackId)s',
      HttpMethod.DELETE,
      {
        path: ['playlistId', 'trackId'],
      },
    ),
    playlistsDmcaCheck: make<PlaylistDmcaValidationResponse, PlaylistDmcaValidationRequest>(
      '/cms/playlists/%(playlistId)s/dmcaValidation/duration',
      HttpMethod.GET,
      {
        path: ['playlistId'],
      },
    ),
    playlistUploadData: make('/cms/playlists/upload/url', HttpMethod.GET),
    setSeedTrack: make<any, any>('/cms/playlists/%(playlistId)s/tracks/seed/%(trackId)s', HttpMethod.PUT, {
      path: ['playlistId', 'trackId'],
    }),
    updateBasicInfo: make('/cms/playlists/%(playlistId)s/basicInfo', HttpMethod.PUT, {
      path: ['playlistId'],
    }),
    adGroupList: make('/cms/adGroups', HttpMethod.GET, {
      query: ['page', 'size'],
    }),
    getAdGroup: make('/cms/playlists/%(playlistId)s/adGroup', HttpMethod.GET, {
      path: ['playlistId'],
    }),
    createAdGroup: make('/cms/playlists/%(playlistId)s/adGroup', HttpMethod.POST, {
      path: ['playlistId'],
    }),
    editAdGroup: make('/cms/playlists/%(playlistId)s/adGroup', HttpMethod.PUT, {
      path: ['playlistId'],
    }),
    listWithoutAdGroup: make<PlaylistsWithoutAdGroupResponse>('/cms/playlists/withoutAdGroup', HttpMethod.GET, {
      query: ['page', 'size'],
    }),
    deleteAdGroup: make<any>('/cms/playlists/%(playlistId)s/adGroup/%(adGroupId)s', HttpMethod.DELETE, {
      path: ['playlistId', 'adGroupId'],
    }),
    getMonetizationTypes: make<MonetizationTypesResponse>('/cms/playlists/monetizationTypes', HttpMethod.GET),
  },
  radioAds: {
    adsList: make('/cms/ads', HttpMethod.GET, {
      query: ['page', 'size', 'id', 'name', 'ageRestriction', 'availability'],
    }),
    getAd: make('/cms/ads/%(id)s', HttpMethod.GET, {
      path: ['id'],
    }),
    createAd: make('/cms/playlists/%(playlistId)s/adGroup/%(adGroupId)s/ads', HttpMethod.POST, {
      path: ['playlistId', 'adGroupId'],
    }),
    editAd: make('/cms/ads/%(id)s', HttpMethod.PATCH, {
      path: ['id'],
    }),
    updateMetadata: make<string, any>('/cms/ads/%(adId)s/metadata', HttpMethod.PUT, {
      path: ['adId'],
    }),
    getUploadData: make('/cms/files/upload/url', HttpMethod.GET, {
      query: ['fileContentType'],
    }),
    deleteAd: make<any>('/cms/ads/%(adId)s', HttpMethod.DELETE, {
      path: ['adId'],
    }),
  },
  stynglist: {
    get: make<EditStynglistEntity>('/cms/stynglists/%(id)s', HttpMethod.GET, {
      path: ['id'],
    }),
    list: make<StynglistsResponse>('/cms/stynglists', HttpMethod.GET, {
      query: ['size', 'page', 'name', 'appId'],
    }),
    create: make<EditStynglistResponse, EditStynglistRequest>('/cms/stynglists', HttpMethod.POST),
    put: make<EditStynglistResponse, EditStynglistRequest>('/cms/stynglists/%(id)s', HttpMethod.PUT, {
      path: ['id'],
    }),
    activate: make('/cms/stynglists/%(stynglistId)s/activate', HttpMethod.POST, {
      path: ['stynglistId'],
    }),
    deactivate: make('/cms/stynglists/%(stynglistId)s/deactivate', HttpMethod.POST, {
      path: ['stynglistId'],
    }),
  },
  soundboard: {
    list: make<SoundboardResponse>('/cms/soundboards', HttpMethod.GET, {
      query: ['size', 'page', 'id', 'name'],
    }),
    create: make<any, any>('/cms/soundboards', HttpMethod.POST),
    deleteSoundboard: make<any>('/cms/soundboards/%(soundboardId)s', HttpMethod.DELETE, {
      path: ['soundboardId'],
    }),
    editGeneralInfo: make<any, any>('/cms/soundboards/%(id)s', HttpMethod.PUT, {
      path: ['id'],
    }),
    getSoundboard: make('/cms/soundboards/%(soundboardId)s', HttpMethod.GET, {
      path: ['soundboardId'],
    }),
    bindSoudboardToApp: make('/cms/soundboards/%(soundboardId)s/apps/%(appId)s', HttpMethod.PUT, {
      path: ['soundboardId', 'appId'],
    }),
    unbindSoudboardToApp: make('/cms/soundboards/%(soundboardId)s/apps/%(appId)s', HttpMethod.DELETE, {
      path: ['soundboardId', 'appId'],
    }),
    publishAppWithinSoudboard: make('/cms/soundboards/%(soundboardId)s/apps/%(appId)s/publish', HttpMethod.PATCH, {
      path: ['soundboardId', 'appId'],
    }),
    unpublishAppWithinSoudboard: make('/cms/soundboards/%(soundboardId)s/apps/%(appId)s/unpublish', HttpMethod.PATCH, {
      path: ['soundboardId', 'appId'],
    }),
    createSoundboardCategory: make('/cms/soundboards/%(soundboardId)s/categories', HttpMethod.POST, {
      path: ['soundboardId'],
    }),
    deleteSoundboardCategory: make('/cms/soundboards/%(soundboardId)s/categories/%(categoryId)s', HttpMethod.DELETE, {
      path: ['soundboardId', 'categoryId'],
    }),
    addStyngToSoundboardCategory: make(
      '/cms/soundboards/%(soundboardId)s/categories/%(categoryId)s/styngs',
      HttpMethod.POST,
      {
        path: ['soundboardId', 'categoryId'],
      },
    ),
    deleteStyngFromSoundboardCategory: make(
      '/cms/soundboards/%(soundboardId)s/categories/%(categoryId)s/styngs/%(styngId)s',
      HttpMethod.DELETE,
      {
        path: ['soundboardId', 'categoryId', 'styngId'],
      },
    ),
    updateStyngExpirationDateInSoundboardCategory: make(
      '/cms/soundboards/%(soundboardId)s/categories/%(categoryId)s/styngs/%(styngId)s',
      HttpMethod.PATCH,
      {
        path: ['soundboardId', 'categoryId', 'styngId'],
        query: ['newExpirationDate'],
      },
    ),
  },
  styngs: {
    get: make<EditStyngEntity>('/cms/styngs/%(id)s', HttpMethod.GET, {
      path: ['id'],
    }),
    list: make<StyngsResponse>('/cms/styngs', HttpMethod.GET, {
      query: ['name', 'page', 'size'],
    }),
    create: make<EditStyngResponse, EditStyngRequest>('/cms/styngs', HttpMethod.POST),
    put: make<EditStyngResponse, EditStyngRequest>('/cms/styngs/%(id)s', HttpMethod.PUT, {
      path: ['id'],
    }),
    activate: make('/cms/styngs/%(styngId)s/activate', HttpMethod.POST, {
      path: ['styngId'],
    }),
    deactivate: make('/cms/styngs/%(styngId)s/deactivate', HttpMethod.POST, {
      path: ['styngId'],
    }),
    getUploadData: make('/cms/styngs/upload/url', HttpMethod.GET),
    updateMetadata: make<string, UpdateMetadataRequest>('/cms/styngs/%(styngId)s/metadata', HttpMethod.POST, {
      path: ['styngId'],
    }),
  },
  nfts: {
    create: make<EditNftResponse, EditNftRequest>('/cms/nfts', HttpMethod.POST),
    list: make<NftsResponse>('/cms/nfts', HttpMethod.GET, {
      query: ['size', 'page'],
    }),
    mint: make<Nft, MintNft>('/cms/nfts/%(id)s/mint', HttpMethod.PATCH, {
      path: ['id'],
    }),
    publishUnpublish: make<boolean, TogglePublishNftRequest>('/cms/nfts/%(id)s/updatePublishStatus', HttpMethod.PATCH, {
      path: ['id'],
    }),
  },
  track: {
    list: make<TracksResponse>('/cms/tracks/search', HttpMethod.GET, {
      query: [
        'title',
        'page',
        'size',
        'format',
        'isrcCodes',
        'genreIds',
        'albumIds',
        'artistIds',
        'labelIds',
        'territoryCodes',
        'labelOwnerIds',
        'mediaNetIds',
        'parentalAdvisory',
      ],
    }),
    lsrList: make<TracksResponse>('/cms/lsr/search', HttpMethod.GET, {
      query: [
        'title',
        'page',
        'size',
        'format',
        'isrcCodes',
        'genreIds',
        'albumIds',
        'artistIds',
        'labelIds',
        'territoryCodes',
        'labelOwnerIds',
        'mediaNetIds',
        'parentalAdvisory',
      ],
    }),
    updateTrackExternalMetadaData: make<string, UpdateTrackExternalMetadataRequest>(
      '/cms/tracks/%(trackMediaNetId)s/update',
      HttpMethod.POST,
      {
        path: ['trackMediaNetId'],
      },
    ),
    updateRoyaltyFreeTrackExternalMetadaData: make<string, UpdateRoyaltyFreeTrackMetadataRequest>(
      '/cms/tracks/royaltyFree/%(trackId)s/update',
      HttpMethod.POST,
      {
        path: ['trackId'],
      },
    ),
    download: make<TrackDownloadResponse>('/cms/tracks/%(trackId)s/url', HttpMethod.GET, {
      path: ['trackId'],
    }),
    getStyngUrl: make<string>('/cms/styngs/url', HttpMethod.GET, {
      query: ['id'],
    }),
    downloadTrack: make<any>(
      '/cms/tracks/download?mediaNetId=%(trackMediaNetId)s&fileFormat=%(fileFormat)s',
      HttpMethod.GET,
      {
        path: ['trackMediaNetId', 'fileFormat'],
      },
    ),
    filters: {
      genres: make<GenresResponse>('/cms/tracks/genres', HttpMethod.GET),
      artists: make<ArtistsResponse>('/cms/artists', HttpMethod.GET, {
        query: ['name', 'limit'],
      }),
      albums: make<AlbumsResponse>('/cms/albums', HttpMethod.GET, {
        query: ['title', 'artistId'],
      }),
      labels: make<LabelsResponse>('/cms/labels', HttpMethod.GET, {
        query: ['name', 'page', 'size'],
      }),
      labelOwners: make<LabelOwnersResponse>('/cms/labelOwners', HttpMethod.GET, {
        query: ['name', 'page', 'size'],
      }),
    },
  },
  app: {
    get: make<AppInfoEntity>('/cms/apps/%(id)s', HttpMethod.GET, {
      path: ['id'],
    }),
    list: make<AppsResponse>('/cms/apps', HttpMethod.GET, {
      query: ['page', 'size', 'name', 'appId'],
    }),
    create: make<any, EditAppRequest>('/cms/apps', HttpMethod.POST),
    put: make<any, EditAppRequest>('/cms/apps/%(id)s', HttpMethod.PUT, {
      path: ['id'],
    }),
    bindStynglist: make<any, BindStynglist>('/cms/apps/%(appId)s/bind', HttpMethod.POST, {
      path: ['appId'],
    }),
    unbindStynglist: make<any, BindStynglist>('/cms/apps/%(appId)s/unbind', HttpMethod.POST, {
      path: ['appId'],
    }),
    delete: make<DeleteUser>('/cms/apps/%(appId)s', HttpMethod.DELETE, {
      path: ['appId'],
    }),
    deleteProductType: make<AppPrice, DeleteAppProdTypeRequest>('/cms/apps/%(appId)s/price', HttpMethod.DELETE, {
      path: ['appId'],
    }),
    addProductType: make<AppPrice, AddAppProdTypeRequest>('/cms/apps/%(appId)s/price', HttpMethod.POST, {
      path: ['appId'],
    }),
    updateProductType: make<AppPrice, UpdateAppProdTypeRequest>('/cms/apps/%(appId)s/price', HttpMethod.PUT, {
      path: ['appId'],
    }),
    updatePricePlan: make<AppPricePlan, UpdateAppPricePlanRequest>('/cms/apps/%(appId)s/pricePlan', HttpMethod.POST, {
      path: ['appId'],
    }),
    getAppPlaylists: make<AppPlaylistsResponse, AppPlaylistsRequest>('/cms/apps/%(appId)s/playlists', HttpMethod.GET, {
      path: ['appId'],
    }),
    getAppFeatures: make('/cms/apps/features/available', HttpMethod.GET),
  },
  platforms: {
    list: make<Platform[]>('/cms/platforms', HttpMethod.GET, {
      query: ['page', 'size'],
    }),
    create: make<PlatformRequest, PlatformResponse>('/cms/platforms', HttpMethod.POST),
    getPlatform: make('/cms/platforms/%(platformId)s', HttpMethod.GET, {
      path: ['platformId'],
    }),
    editPlatform: make<PlatformRequest, PlatformResponse>('/cms/platforms/%(id)s', HttpMethod.PUT, {
      path: ['id'],
    }),
  },
  company: {
    get: make<EditCompanyEntity>('/cms/companies/%(companyId)s', HttpMethod.GET, {
      path: ['companyId'],
    }),
    list: make<CompaniesResponse>('/cms/companies', HttpMethod.GET),
    all: make<CompaniesResponse>('/cms/companies/all', HttpMethod.GET, {
      query: ['page', 'size', 'name', 'id'],
    }),
    create: make<EditCompanyResponse, EditCompanyRequest>('/cms/companies', HttpMethod.POST),
    update: make<EditCompanyResponse, EditCompanyRequest>('/cms/companies/%(companyId)s', HttpMethod.PUT, {
      path: ['companyId'],
    }),
  },
  event: {
    get: make<EditAppEventEntity>('/cms/apps/%(appId)s/events/%(id)s', HttpMethod.GET, {
      path: ['appId', 'id'],
    }),
    list: make<AppEventsResponse>('/cms/apps/%(appId)s/events', HttpMethod.GET, {
      path: ['appId'],
    }),
    create: make<EditAppEventResponse, EditAppEventRequest>('/cms/apps/%(appId)s/events', HttpMethod.POST, {
      path: ['appId'],
    }),
    put: make<EditAppEventResponse, EditAppEventRequest>('/cms/apps/%(appId)s/events/%(id)s', HttpMethod.PUT, {
      path: ['appId', 'id'],
    }),
  },
  currency: {
    get: make<EditCurrencyEntity>('/cms/currencies/%(currencyId)s', HttpMethod.GET, {
      path: ['currencyId'],
    }),
    list: make<CurrenciesResponse>('/cms/currencies', HttpMethod.GET, {
      query: ['appId'],
    }),
    create: make<EditCurrencyResponse, EditCurrencyRequest>('/cms/currencies', HttpMethod.POST),
    update: make<EditCurrencyResponse, EditCurrencyRequest>('/cms/currencies/%(currencyId)s', HttpMethod.PUT, {
      path: ['currencyId'],
    }),
  },
  device: {
    register: make<DeviceRegisterResponse, DeviceRegisterRequest>('/auth/devices/register', HttpMethod.POST),
  },
  token: {
    refresh: make('/auth/tokens/refresh', HttpMethod.POST),
  },
  image: {
    get: make<ImageUrl>('/cms/files/images/%(id)s', HttpMethod.GET, {
      path: ['id'],
    }),
    create: make<ImageEntity, any>('/cms/files/images', HttpMethod.POST),
    postUrl: make<ImageEntity, any>('/cms/files/images/url', HttpMethod.POST),
  },
  territories: {
    list: make<TerritoriesResponse>('/cms/territories', HttpMethod.GET),
  },
  reports: {
    list: make<ReportsResponse>('/cms/reports/requests', HttpMethod.GET, {
      query: ['page', 'size'],
    }),
    getReportTypes: make<ReportTypesResponse>('/cms/reports/types', HttpMethod.GET),
    generateReport: make<any>('/cms/reports/requests', HttpMethod.POST),
    generateReportResubmit: make<any>('/cms/reports/requests/%(requestId)s/resubmit', HttpMethod.POST, {
      path: ['requestId'],
    }),
  },
}

export default API
