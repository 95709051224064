/* eslint-disable jsx-control-statements/jsx-for-require-each */
import React from 'react'
import * as R from 'ramda'
import MuiSelect, { SelectChangeEvent } from '@mui/material/Select'
import MenuItem from '@mui/material/MenuItem'
import InputLabel from '@mui/material/InputLabel'
import FormControl from '@mui/material/FormControl'
import { OptionType } from 'common/api/common/common'
import FormHelperText from '@mui/material/FormHelperText'
import ClearIcon from '@mui/icons-material/Clear'

interface SelectProps {
  value: string
  options: OptionType[]
  onChange: (value: string) => void
  disabled?: boolean
  className?: string
  name?: string
  label?: string
  error?: string
  clear?: boolean
  fullWidth?: boolean
  required?: boolean
  multiple?: boolean
  onClickOption?: () => void
}

const Select = ({
  options,
  value,
  name,
  error,
  clear = false,
  multiple = false,
  disabled = false,
  className,
  label = '',
  fullWidth,
  required,
  onChange,
  onClickOption,
}: SelectProps) => {
  const handleChange = (event: SelectChangeEvent) => {
    const eventValue = event.target.value

    onChange(eventValue)
  }

  const handleClear = () => {
    onChange('')
  }

  return (
    <FormControl error={error ? true : false} className={className} fullWidth={fullWidth}>
      <InputLabel>{required ? `${label} *` : label}</InputLabel>
      <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
        <MuiSelect
          fullWidth={true}
          value={value}
          multiple={multiple}
          label={required ? `${label}*` : label}
          name={name}
          error={!R.isEmpty(error) && !R.isNil(error)}
          disabled={disabled}
          MenuProps={{
            style: {
              width: 1,
              maxHeight: 300,
            },
          }}
          onChange={handleChange}
        >
          {options.map((option: OptionType) => {
            return (
              <MenuItem
                sx={{
                  whiteSpace: 'unset',
                  maxWidth: '100%',
                  wordWrap: 'break-word',
                }}
                value={option.value}
                key={option.value}
                onClick={onClickOption}
              >
                {option.label}
              </MenuItem>
            )
          })}
        </MuiSelect>
        {clear && value !== '' && (
          <ClearIcon color="action" sx={{ marginLeft: 1, cursor: 'pointer' }} onClick={() => handleClear()} />
        )}
      </div>
      {error ? <FormHelperText error>{error}</FormHelperText> : null}
    </FormControl>
  )
}

export default Select
