/* eslint-disable jsx-control-statements/jsx-for-require-each */
import { Duration } from 'luxon'

import useStores from 'common/hook/useStore'
import { getCustomErrorNotificationByCode } from 'ui/Snackbar/SnackbarHelper'

import { CreateStynglistRoute, EditStynglistRoute } from '../../index'

import { Stynglist, ActivateStynglist, DeactivateStynglist } from 'common/api/stynglist/stynglist'
import Table from 'ui/Table/Table'
import { useMutation } from 'react-query'
import { formatGenres } from 'common/hook/formatGenre'
import Button from 'ui/Button/Button'
import API from 'common/api'
import Text from 'locale/strings'

import { Permission } from 'common/constants'
import Pagination from 'ui/Pagination/Pagination'

import styles from './StynglistsTable.module.scss'

interface StynglistsProps {
  stynglists: Stynglist[]
  pagesCount: any
  refetch: any
}

const StynglistsTable = ({ stynglists, refetch, pagesCount }: StynglistsProps) => {
  const { navigationStore, userStore, stynglistsStore, notificationsStore } = useStores()

  const columns = ['Image', 'Id', 'Name', 'Genres', 'Duration', 'Published', 'Actions']

  const dataTable = stynglists?.map((stynglists: Stynglist) => [
    stynglists?.imageUrl,
    stynglists?.id,
    stynglists?.name,
    formatGenres(stynglists?.genres),
    Duration.fromISO(stynglists?.duration).toFormat('mm:ss'),
    stynglists.isActive.toString(),
  ])

  const onActivateMutation = useMutation<null, Error, ActivateStynglist>(
    (id) => {
      return API.stynglist.activate(id)
    },
    {
      onSuccess: () => {
        refetch()
      },
      onError: (error: any) => {
        const errorObj = JSON.stringify(error)
        const parseObj = JSON.parse(errorObj)
        const errorCode = parseObj.errorCode
        const errorMessage = getCustomErrorNotificationByCode(errorCode)

        notificationsStore.errorNotification(errorMessage)
      },
    },
  )

  const onDeactivateMutation = useMutation<null, Error, DeactivateStynglist>(
    (id) => {
      return API.stynglist.deactivate(id)
    },
    {
      onSuccess: () => {
        refetch()
      },
      onError: (error: any) => {
        const errorObj = JSON.stringify(error)
        const parseObj = JSON.parse(errorObj)
        const errorCode = parseObj.errorCode
        const errorMessage = getCustomErrorNotificationByCode(errorCode)

        notificationsStore.errorNotification(errorMessage)
      },
    },
  )

  const handleActivate = (id: string, active: string) => {
    if (active === 'false') {
      onActivateMutation.mutate({ stynglistId: id })
    } else {
      onDeactivateMutation.mutate({ stynglistId: id })
    }
  }

  const handleCreateClick = () => {
    navigationStore.goToPage(CreateStynglistRoute.path)
  }

  const handleEditClick = (id: string) => {
    navigationStore.goToPage(EditStynglistRoute.getUrl({ id: id }))
  }

  const handleChangePage = (value: number) => {
    stynglistsStore.changePage(value)
  }

  return (
    <div className={styles.container}>
      <If condition={userStore.hasPermission(Permission.CREATE_STYNGLIST)}>
        <div className={styles.header}>
          <Button data-test="create-button" onClick={() => handleCreateClick()}>
            {Text.page.stynglists.newStynglist}
          </Button>
        </div>
      </If>
      <Table
        hideDelete
        columns={columns}
        data={dataTable}
        publishedToggle={true}
        handleActivate={handleActivate}
        onEditClick={handleEditClick}
      />
      <If condition={pagesCount > 1}>
        <div className={styles.paginationContainer}>
          <div className={styles.pagination}>
            <Pagination page={stynglistsStore.page} count={pagesCount} onChange={handleChangePage} />
          </div>
        </div>
      </If>
    </div>
  )
}

export default StynglistsTable
