import * as R from 'ramda'
import CssBaseline from '@mui/material/CssBaseline'
import React, { useEffect } from 'react'
import { Provider } from 'mobx-react'
import { useHistory } from 'react-router-dom'
import { QueryClient, QueryClientProvider } from 'react-query'

import AuthStore from './auth/AuthStore'
import DeviceRegister from './device/DeviceRegister'
import DeviceStore from './device/DeviceStore'
import UserStore from './user/UserStore'
import PlatformsStore from './platforms/PlatformsStore'
import CompaniesStore from './companies/CompaniesStore'
import NavigationStore from './navigation/NavigationStore'
import ApplicationStore from './apps/ApplicationStore'
import PlaylistsStore from './playlists/PlaylistsStore'
import StynglistsStore from './stynglists/StynglistsStore'
import SoundboardStore from './soundboard/SoundboardStore'
import StyngsStore from './styngs/StyngsStore'
import TracksInPlaylistStore from './playlists/pages/EditPlaylist/TracksInPlaylistStore'
import NftsStore from './nfts/NftsStore'
import RadioAdsStore from './radioAds/RadioAdsStore'
import AdGroupsStore from './radioAdGroups/AdGroupsStore'
import TerritoriesStore from './territories/TerritoriesStore'
import ReportsStore from './reporting/ReportingStore'
import NotificationsStore from './notifications/NotificationsStore'
import ActivityLogStore from './activityLog/ActivityLogStore'

import Storage from './common/storage'
import { RootStore } from './common/hook/useStore'

import './common/styles/index.scss'
import { getAdminMode } from './common/utils'
import EditStyngStore from './styngs/CreateStyng/CreateStyngStore'

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      cacheTime: 0,
    },
  },
})

const App: React.FC = ({ children }) => {
  const history = useHistory()

  const stores: RootStore = {
    deviceStore: new DeviceStore(),
    authStore: new AuthStore(),
    applicationStore: new ApplicationStore(),
    userStore: new UserStore(),
    platformsStore: new PlatformsStore(),
    companiesStore: new CompaniesStore(),
    playlistsStore: new PlaylistsStore(),
    styngsStore: new StyngsStore(),
    nftsStore: new NftsStore(),
    stynglistsStore: new StynglistsStore(),
    soundboardStore: new SoundboardStore(),
    editStyngStore: new EditStyngStore(),
    navigationStore: new NavigationStore(history),
    tracksInPlaylistStore: new TracksInPlaylistStore(),
    radioAdsStore: new RadioAdsStore(),
    adGroupsStore: new AdGroupsStore(),
    territoriesStore: new TerritoriesStore(),
    reportsStore: new ReportsStore(),
    notificationsStore: new NotificationsStore(),
    activityLogStore: new ActivityLogStore(),
  }

  useEffect(() => {
    const initialAdminMode = getAdminMode(Storage.get('adminMode'))

    if (!R.isNil(initialAdminMode)) {
      stores.applicationStore.setAdminMode(initialAdminMode)
    }
  }, [])

  return (
    <Provider {...stores}>
      <QueryClientProvider client={queryClient}>
        <DeviceRegister>
          <CssBaseline />
          {children}
        </DeviceRegister>
      </QueryClientProvider>
    </Provider>
  )
}

export default App
