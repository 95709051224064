/* eslint-disable jsx-control-statements/jsx-use-if-tag */
import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import * as R from 'ramda'
import { observer } from 'mobx-react'
import { useFormik } from 'formik'
import * as yup from 'yup'
import { useMutation, useQueryClient } from 'react-query'

import useStores from '../../../common/hook/useStore'
import { getCustomErrorNotificationByCode } from '../../../ui/Snackbar/SnackbarHelper'

import { Toolbar, Typography, Collapse, IconButton } from '@mui/material'

import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp'

import Text from '../../../locale/strings'
import Button, { ButtonVariant } from '../../../ui/Button/Button'
import Select from '../../../ui/Select/Select'
import TextField from '../../../ui/TextField/TextField'

import API from '../../../common/api'
import { useAppQuery } from '../../ApplicationStore'
import { useAllCompaniesQuery } from '../../../companies/CompaniesStore'

import { AppsRoute } from '../../pages'

import Features from '../../Features/Features'
import PricePlan from '../../PricePlan/PricePlan'
import Prices from '../../Prices/Prices'

import {
  EditAppResponse,
  EditAppRequest,
  AppPrice,
  AppPricePlan,
  AppFeatures,
  PricePlanValues,
} from '../../../common/api/app/app'
import { OptionType } from '../../../common/api/common/common'
import Spinner from '../../../ui/Spinner/Spinner'
import { toCompanyOptions } from '../../../common/constants'

import styles from './AppGeneralFormPage.module.scss'

const URL_REG_EX =
  /((https?):\/\/)?(www.)?[a-z0-9]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$/

interface ParamProps {
  appId?: string
}

const schema = yup.object({
  name: yup.string().required('Name is required'),
  companyId: yup.string().required('Company is required'),
  integrationType: yup.string().required('Integration type is required'),
  linkPayout: yup.string().matches(URL_REG_EX, 'Enter correct url'),
})

interface AppFormValues {
  companyId: string
  integrationType: string
  name: string
  paymentSystemUrl: string
  prices: AppPrice[]
  pricePlan: AppPricePlan[]
  features: AppFeatures[]
}

const initialValues: AppFormValues = {
  companyId: '',
  integrationType: '',
  name: '',
  paymentSystemUrl: '',
  prices: [],
  pricePlan: [],
  features: [],
}

const integrationTypes = [
  { value: 'SDK', label: 'SDK' },
  { value: 'API', label: 'API' },
]

const defaultPricePlan: PricePlanValues = {
  revenueShare: 0.65,
  deductionShare: 0.3,
  taxShare: 0.1,
  pricePerStream: 0.0046,
}

const AppGeneralFormPage = () => {
  const { navigationStore, notificationsStore, companiesStore } = useStores()
  const { appId = '' } = useParams<ParamProps>()

  const [newPrices, setNewPrices] = useState<AppPrice[]>([])
  const [newFeatures, setNewFeatures] = useState<AppFeatures[]>([])
  const [newPricePlan, setNewPricePlan] = useState<PricePlanValues>(defaultPricePlan)

  const queryClient = useQueryClient()
  const { data: companiesData } = useAllCompaniesQuery(companiesStore)
  const [companyOptions, setCompanyOptions] = useState<OptionType[]>([])
  const { data: app } = useAppQuery(appId)

  const [openFeatures, setOpenFeatures] = React.useState<boolean>(false)
  const [openPricePlan, setOpenPricePlan] = React.useState<boolean>(false)
  const [openPrices, setOpenPrices] = React.useState<boolean>(false)

  const initialApp = appId !== '' ? app : null

  const mutationAppEvent = useMutation<EditAppResponse, Error, EditAppRequest>(
    (body) => {
      if (R.isNil(initialApp)) {
        return API.app.create(body)
      }

      return API.app.put(body)
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries('app')
        navigationStore.goToPage(AppsRoute.path)

        initialApp
          ? notificationsStore.successNotification('App successfully edited')
          : notificationsStore.successNotification('App successfully created')
      },
      onError: (error) => {
        const errorObj = JSON.stringify(error)
        const parseObj = JSON.parse(errorObj)
        const errorCode = parseObj.errorCode
        const errorMessage = getCustomErrorNotificationByCode(errorCode)

        notificationsStore.errorNotification(errorMessage)
      },
    },
  )

  useEffect(() => {
    if (companiesData) {
      setCompanyOptions(companiesData?.items.map(toCompanyOptions))
    }
  }, [companiesData])

  useEffect(() => {
    if (app?.features !== undefined) {
      setNewFeatures((prevState) => [...prevState, ...app.features])
    }

    if (app?.pricePlans !== undefined) {
      setNewPricePlan(app.pricePlans[0])
    }

    if (app?.prices !== undefined) {
      setNewPrices(app.prices)
    }
  }, [app])

  const handleSubmit = async (values: AppFormValues) => {
    const allValuesPopulated = Object.values(newPricePlan).every((value) => value !== '')

    const isValid = formik.dirty && allValuesPopulated && (!R.isEmpty(values.companyId) || !R.isNil(initialApp))

    if (isValid) {
      const createBody = {
        id: initialApp?.id,
        name: values.name.trim(),
        companyId: values.companyId,
        paymentSystemUrl: values.paymentSystemUrl ? values.paymentSystemUrl.trim() : null,
        integrationType: values.integrationType,
        pricePlan: newPricePlan,
        prices: newPrices,
        features: newFeatures,
      }

      const editBody = {
        id: initialApp?.id,
        name: values.name.trim(),
        companyId: values.companyId,
        paymentSystemUrl: values.paymentSystemUrl ? values.paymentSystemUrl.trim() : null,
        integrationType: values.integrationType,
        pricePlan: newPricePlan,
        prices: newPrices,
        features: newFeatures,
      }

      if (initialApp) {
        mutationAppEvent.mutate(R.omit(['companyId'], editBody))
      } else {
        mutationAppEvent.mutate(createBody)
      }
    }
  }

  const formik = useFormik<AppFormValues>({
    initialValues: initialValues,
    validationSchema: schema,
    validateOnBlur: false,
    validateOnChange: false,
    onSubmit: handleSubmit,
  })

  const { values, resetForm, setFieldValue } = formik

  useEffect(() => {
    if (!R.isNil(initialApp)) {
      resetForm({
        values: {
          name: initialApp.name,
          paymentSystemUrl: initialApp.paymentSystemUrl ?? '',
          companyId: initialApp.company.id,
          prices: initialApp.prices,
          integrationType: initialApp.integrationType ?? '',
          pricePlan: initialApp.pricePlans ?? [newPricePlan],
          features: initialApp.features,
        },
      })
    }
  }, [initialApp, resetForm])

  const handleChangeCompanyId = (value: string) => {
    setFieldValue('companyId', value)
  }

  const handleChangeIntegrationType = (value: string) => {
    setFieldValue('integrationType', value)
  }

  useEffect(() => {
    setFieldValue('features', newFeatures)
  }, [newFeatures])

  useEffect(() => {
    setFieldValue('pricePlan', newPricePlan)
  }, [newPricePlan])

  useEffect(() => {
    setFieldValue('prices', newPrices)
  }, [newPrices])

  return (
    <React.Fragment>
      {appId !== '' && R.isNil(initialApp) ? (
        <Spinner />
      ) : (
        <React.Fragment>
          <Toolbar className={styles.toolbar}>
            <Typography sx={{ ml: 2, flex: 1, color: '#fff' }} variant="h6" component="div">
              {!R.isNil(initialApp) ? Text.page.app.editApp : Text.page.app.createApp}
            </Typography>
          </Toolbar>
          <div className={styles.container}>
            <div className={styles.header}>{Text.page.app.generalInformation}</div>
            <div className={styles.form}>
              <TextField
                fullWidth
                data-test="name-field"
                label={Text.page.appEvents.create.fields.name}
                className={styles.input}
                name="name"
                value={values.name}
                error={formik.errors.name}
                onChange={formik.handleChange}
              />
              <TextField
                fullWidth
                data-test="payment-system-url-field"
                label={Text.fields.linkPayout}
                name="paymentSystemUrl"
                autoComplete="off"
                value={values.paymentSystemUrl}
                className={styles.input}
                error={formik.errors.paymentSystemUrl}
                onChange={formik.handleChange}
              />
              <Select
                fullWidth
                data-test="company-id-field"
                disabled={!R.isNil(initialApp)}
                className={styles.select}
                label={Text.fields.company}
                name="companyId"
                value={values.companyId}
                options={companyOptions}
                error={formik.errors.companyId}
                onChange={handleChangeCompanyId}
              />

              <Select
                fullWidth
                data-test="integration-type-field"
                className={styles.select}
                label={Text.fields.integrationType}
                name="integrationType"
                value={values.integrationType}
                options={integrationTypes}
                error={formik.errors.integrationType}
                onChange={handleChangeIntegrationType}
              />

              <div className={styles.header}>
                {Text.page.app.features}
                <IconButton aria-label="expand row" size="small" onClick={() => setOpenFeatures(!openFeatures)}>
                  {openFeatures ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                </IconButton>
              </div>
              <Collapse unmountOnExit className={styles.collapse} timeout="auto" in={openFeatures}>
                <Features features={newFeatures} setNewFeatures={setNewFeatures} />
              </Collapse>

              <div className={styles.header}>
                {Text.page.app.pricePlan}
                <IconButton aria-label="expand row" size="small" onClick={() => setOpenPricePlan(!openPricePlan)}>
                  {openPricePlan ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                </IconButton>
              </div>
              <Collapse unmountOnExit className={styles.collapse} timeout="auto" in={openPricePlan}>
                <PricePlan
                  pricePlan={newPricePlan}
                  setNewPricePlan={setNewPricePlan}
                  initialPricePlan={initialApp?.pricePlans}
                />
              </Collapse>

              <div className={styles.header}>
                {Text.page.app.prices}
                <IconButton aria-label="expand row" size="small" onClick={() => setOpenPrices(!openPrices)}>
                  {openPrices ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                </IconButton>
              </div>
              <Collapse unmountOnExit className={styles.collapse} timeout="auto" in={openPrices}>
                <Prices
                  appId={appId}
                  prices={R.isNil(initialApp) ? newPrices : app?.prices}
                  setNewPrices={setNewPrices}
                />
              </Collapse>
            </div>
          </div>
          <div className={styles.submitContainer}>
            <Button
              data-test="cancel-button"
              variant={ButtonVariant.OUTLINED}
              onClick={() => navigationStore.goToPage(AppsRoute.path)}
            >
              {Text.common.cancel}
            </Button>
            <Button
              data-test="submit-button"
              disabled={!R.isNil(initialApp) && !(formik.isValid && formik.dirty)}
              loading={mutationAppEvent.isLoading}
              onClick={formik.handleSubmit}
            >
              {Text.common.save}
            </Button>
          </div>
        </React.Fragment>
      )}
    </React.Fragment>
  )
}

export default observer(AppGeneralFormPage)
