/* eslint-disable jsx-control-statements/jsx-for-require-each */
import { observer } from 'mobx-react'

import { thread } from 'lib/fn/thread'

import StyngResult from './StyngResult/StyngResult'
import { Styng } from '../../common/api/styng/styng'
import StyngsHOC from '../../styngs/StyngsHOC'
import styles from './styngsFilter.module.scss'
import Text from '../../locale/strings'
import { useStyngsQuery } from '../../styngs/StyngsStore'

interface StyngsFilterProps {
  onClickStyng: (styng: Styng) => void
}

const StyngsFilter = ({ onClickStyng }: StyngsFilterProps) => {
  const { data } = useStyngsQuery()
  const styngs = data?.items ?? []

  return (
    <div className={styles.styngFilter}>
      {styngs.length !== 0 ? (
        <For of={styngs} body={(styng: Styng) => <StyngResult key={styng.id} styng={styng} onClick={onClickStyng} />} />
      ) : (
        <p className={styles.styngNoResult}>{Text.search.emptyData}</p>
      )}
    </div>
  )
}

export default thread(StyngsFilter, [observer, StyngsHOC])
