/* eslint-disable react/jsx-sort-props */
/* eslint-disable jsx-control-statements/jsx-for-require-each */
import * as React from 'react'
import TableMui from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import Paper from '@mui/material/Paper'
import Row from './Row/Row'
import Text from '../../locale/strings'

import styles from './table.module.scss'

interface TableProps {
  data: any
  columns: string[]
  sx?: any
  size?: 'small' | 'medium'
  stickyHeader?: boolean
  jsonData?: boolean
  createStyng?: boolean
  plusIcon?: boolean
  hideActionColumn?: boolean
  disabledEdit?: boolean
  disabledPlus?: boolean
  disabledDelete?: boolean
  hideDelete?: boolean
  hideEdit?: boolean
  hideColData?: boolean
  publishedToggle?: boolean
  mintButton?: boolean
  dmcaCheckButton?: boolean
  disabledToggle?: boolean
  disabledPlaylistToggle?: boolean
  nftIcon?: boolean
  hiddenColumns?: string[]
  dataAdditionalInfo?: any
  tableMaxHeight?: Nullable<number>
  showDownloadTrack?: boolean
  additionalDataComponent?: (row: string[]) => React.ReactElement
  onClickRow?: (id: string) => void
  onEditClick?: (id: string) => void
  onCreateNftClick?: (id: string) => void
  onDmcaCheckClick?: (id: string, name?: string) => void
  onDeleteClick?: (id: string, name: string) => void
  handleActivate?: (id: string, active: string) => any
  prepareForMintNft?: (id: string) => void
  onJsonDataClick?: (id: string) => void
  onDownloadFile?: (id: string) => void
  onResubmitClick?: (id: string) => void
}

const Table = ({
  sx,
  size = 'medium',
  hideActionColumn = false,
  stickyHeader = false,
  tableMaxHeight = null,
  createStyng,
  plusIcon,
  disabledEdit,
  disabledPlus,
  disabledDelete,
  disabledToggle,
  disabledPlaylistToggle,
  hideDelete,
  hideEdit,
  hideColData,
  mintButton,
  dmcaCheckButton,
  columns,
  data,
  dataAdditionalInfo,
  publishedToggle,
  nftIcon,
  jsonData,
  hiddenColumns,
  showDownloadTrack,
  additionalDataComponent,
  handleActivate,
  onEditClick,
  onCreateNftClick,
  onDmcaCheckClick,
  onDeleteClick,
  prepareForMintNft,
  onJsonDataClick,
  onDownloadFile,
  onResubmitClick,
}: TableProps) => {
  const columnsData = dataAdditionalInfo ? ['', ...columns] : columns

  return (
    <TableContainer data-test="table-container" component={Paper} sx={{ maxHeight: tableMaxHeight }}>
      {data.length > 0 ? (
        <TableMui
          data-test="table"
          stickyHeader={stickyHeader}
          sx={{
            ...sx,
            minWidth: 650,
            '& .MuiTableCell-sizeMedium': {
              padding: '15px 10px',
            },
          }}
          size={size}
          aria-label="simple table"
        >
          <TableHead data-test="table-head">
            <TableRow>
              <For
                of={columnsData}
                body={(column: string) => (
                  <TableCell className={hiddenColumns?.includes(column) ? styles.hidden : ''} key={column}>
                    {column}
                  </TableCell>
                )}
              />
            </TableRow>
          </TableHead>
          <TableBody data-test="table-body">
            <For
              of={data}
              body={(row: string[], indexRow) => (
                <Row
                  row={row}
                  indexRow={indexRow}
                  key={indexRow}
                  columns={columnsData}
                  hideActionColumn={hideActionColumn}
                  createStyng={createStyng}
                  plusIcon={plusIcon}
                  disabledPlus={disabledPlus}
                  disabledEdit={disabledEdit}
                  hideEdit={hideEdit}
                  disabledDelete={disabledDelete}
                  disabledToggle={disabledToggle}
                  disabledPlaylistToggle={disabledPlaylistToggle}
                  hideDelete={hideDelete}
                  hideColData={hideColData}
                  hiddenColumns={hiddenColumns}
                  nftIcon={nftIcon}
                  mintButton={mintButton}
                  dmcaCheckButton={dmcaCheckButton}
                  dataAdditionalInfo={dataAdditionalInfo}
                  additionalDataComponent={additionalDataComponent}
                  publishedToggle={publishedToggle}
                  showDownloadTrack={showDownloadTrack}
                  handleActivate={handleActivate}
                  onEditClick={onEditClick}
                  onCreateNftClick={onCreateNftClick}
                  onDmcaCheckClick={onDmcaCheckClick}
                  onDeleteClick={onDeleteClick}
                  prepareForMintNft={prepareForMintNft}
                  jsonData={jsonData}
                  onJsonDataClick={onJsonDataClick}
                  onDownloadFile={onDownloadFile}
                  onResubmitClick={onResubmitClick}
                />
              )}
            />
          </TableBody>
        </TableMui>
      ) : (
        <div className={styles.tableMsg}>
          <p>{Text.common.noData}</p>
        </div>
      )}
    </TableContainer>
  )
}

export default Table
