/* eslint-disable @typescript-eslint/indent */
import React, { useState, useEffect } from 'react'
import { observer } from 'mobx-react'
import { thread } from 'lib/fn/thread'
import { useFormik } from 'formik'
import * as yup from 'yup'
import { useMutation } from 'react-query'

import { useAppsQuery } from 'apps/ApplicationStore'
import { useStyngsQuery } from 'styngs/StyngsStore'
import { SoundboardRoute } from 'soundboard'

import { DateTime } from 'luxon'

import { Toolbar, Typography, Box } from '@mui/material'

import Text from 'locale/strings'
import Button, { ButtonVariant } from 'ui/Button/Button'
import Spinner from 'ui/Spinner/Spinner'
import ConfirmationModal from 'ui/ConfirmationModal/ConfirmationModal'
import { getCustomErrorNotificationByCode } from 'ui/Snackbar/SnackbarHelper'

import API from 'common/api'
import { Soundboard, Category } from 'common/api/soundboard/soundboard'
import { Application } from 'common/api/app/app'
import useStores from 'common/hook/useStore'
import { ApiStatuses } from 'common/constants'
import { ImageEntity } from 'common/api/image/image'

import General from './../../components/General/General'
import BindApplications from './../../components/BindApplications/BindApplications'
import Categories from './../../components/Categories/Categories'

import styles from './CreateSoundboard.module.scss'

const schema = yup.object().shape({
  name: yup
    .string()
    .matches(/^\S.*\S$|^\S$/, 'No leading or trailing spaces are allowed')
    .required('Name is required')
    .max(50, 'Name must be at most 50 characters'),
  subscriptionPrice: yup
    .number()
    .typeError('Must be a number')
    .min(0, 'Field value must be greater than or equal to 0'),
  styngPrice: yup.number().typeError('Must be a number').min(0, 'Field value must be greater than or equal to 0'),
})

const CreateSoundboard = () => {
  const { notificationsStore, applicationStore, styngsStore, navigationStore } = useStores()
  const { data: apps, status: appsStatus } = useAppsQuery(applicationStore)

  const { data: styngs, status: styngsStatus } = useStyngsQuery()
  const styngsPagesCount = styngs?.pagesCount ?? 1

  const [showConfirmationModal, setShowConfirmationModal] = useState<boolean>(false)
  const [soundboardValues, setSoundboardValues] = useState<Soundboard>()

  const [allApps, setAllApps] = useState<Application[]>([])
  const [selectedApps, setSelectedApps] = useState<Application[]>([])

  const [categoriesList, setCategoriesList] = useState<Category[]>([])

  useEffect(() => {
    if (apps?.apps) {
      setAllApps(apps?.apps)
    }
  }, [apps])

  const mutationCreateSoundboard = useMutation<any, Error, any>(
    (body) => {
      return API.soundboard.create(body)
    },
    {
      onSuccess: () => {
        resetForm()
        setShowConfirmationModal(false)
        navigationStore.goToPage(SoundboardRoute.path)

        notificationsStore.successNotification('Soundboard successfully added')
      },
      onError: (error) => {
        setShowConfirmationModal(false)

        const errorObj = JSON.stringify(error)
        const parseObj = JSON.parse(errorObj)
        const errorCode = parseObj.errorCode
        const errorMessage = getCustomErrorNotificationByCode(errorCode)

        notificationsStore.errorNotification(errorMessage)
      },
    },
  )

  const mutationImage = useMutation<ImageEntity, Error, any>(
    (body) => {
      return API.image.create(body)
    },
    {
      onSuccess: (data: ImageEntity) => {
        formik.setFieldValue('imageId', data.id)
      },
    },
  )

  const [initialValues] = useState({
    id: '',
    name: '',
    subscriptionPrice: '',
    styngPrice: '',
    sourceType: 'LSR',
    imageId: '',
    appIds: [],
    categories: [],
  })

  // General
  const handleSoundboardImageUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files) {
      const file = event.target.files[0]

      if (file.type.includes('image/')) {
        const formData = new FormData()

        formData.append('filename', file.name)
        formData.append('file', file)

        mutationImage.mutate(formData)
      }
    }
  }

  // handle apps
  const handleChangeApps = (e: React.SyntheticEvent, value: Application[]) => {
    setSelectedApps(value)
  }

  const handleDeleteApp = (value: any) => {
    setSelectedApps((appArray) => appArray.filter((appOption) => appOption.id !== value))
  }

  // handle categories
  const addNewCategory = (categoryName: string) => {
    if (categoryName.length === 0 || categoriesList.some((category) => category.name === categoryName)) {
      return
    }

    setCategoriesList((currentCategoriesList) => [{ id: '', name: categoryName, styngs: [] }, ...currentCategoriesList])
  }

  const removeCategory = (categoryIndex: number) => {
    setCategoriesList([...categoriesList.slice(0, categoryIndex), ...categoriesList.slice(categoryIndex + 1)])
  }

  const addStyngToCategory = (styng: any, selectedCategoryIndex: number | null) => {
    if (selectedCategoryIndex === null) {
      return
    }

    setCategoriesList((categoriesList: Category[]) => {
      const newCategoriesList = categoriesList.map((item) => ({
        ...item,
        styngs: [...item.styngs],
      }))

      if (newCategoriesList[selectedCategoryIndex].styngs.some((styngObject) => styngObject.id === styng.id)) {
        return categoriesList
      }

      newCategoriesList[selectedCategoryIndex].styngs.push(styng)

      return newCategoriesList
    })
  }

  const removeStyngFromCategory = (styngId: string, selectedCategoryIndex: number) => {
    if (selectedCategoryIndex === null) {
      return
    }

    setCategoriesList((categoriesList: any) => {
      const newCategoriesList = [...categoriesList]

      if (newCategoriesList[selectedCategoryIndex]?.styngs) {
        newCategoriesList[selectedCategoryIndex].styngs = newCategoriesList[selectedCategoryIndex].styngs.filter(
          (styng: any) => styng.id !== styngId,
        )
      }

      return newCategoriesList
    })
  }

  const setExpirationDate = (value: any, styngId: string, selectedCategoryIndex: number) => {
    const updatedCategoriesList = categoriesList.map((category, index) => ({
      ...category,
      styngs:
        selectedCategoryIndex === index
          ? category.styngs.map((styng) =>
              styng.id === styngId ? { ...styng, expiresAt: value.toFormat('yyyy-MM-dd') } : styng,
            )
          : category.styngs.map((styng) => styng),
    }))

    setCategoriesList(updatedCategoriesList)
  }

  const handleStyngsChangePage = (value: number) => {
    styngsStore.changePage(value)
  }

  const handleCloseConfirmationModal = () => {
    setShowConfirmationModal(false)
  }

  const handleSubmit = (values: Soundboard) => {
    values.appIds = selectedApps.map((app) => app.id)
    values.categories = categoriesList

    const currentDay = DateTime.now().startOf('day')
    let styngExpireAlert = false

    const allDates: any[] = []

    categoriesList.forEach((category) => {
      category.styngs?.forEach((styng) => {
        if (typeof styng.expiresAt !== 'undefined') {
          allDates.push(styng.expiresAt)
        }
      })
    })

    allDates.forEach((date) => {
      const selectedDate = DateTime.fromFormat(date, 'yyyy-MM-dd')
      const range = Math.abs(selectedDate.diff(currentDay, 'days').days)

      if (range < 7) {
        return (styngExpireAlert = true)
      }
    })

    if (styngExpireAlert) {
      setShowConfirmationModal(true)
      setSoundboardValues(values)
    } else {
      mutationCreateSoundboard.mutate(values)
    }
  }

  const handleSubmitValues = () => {
    mutationCreateSoundboard.mutate(soundboardValues)
  }

  const formik = useFormik<Soundboard>({
    initialValues: initialValues,
    validationSchema: schema,
    validateOnBlur: false,
    validateOnChange: false,
    enableReinitialize: true,
    onSubmit: handleSubmit,
  })

  const { handleChange, errors, values, resetForm } = formik

  return (
    <Box>
      {appsStatus === ApiStatuses.LOADING || styngsStatus === ApiStatuses.LOADING ? (
        <Spinner />
      ) : (
        <React.Fragment>
          <Toolbar className={styles.toolbar}>
            <Typography sx={{ ml: 2, flex: 1, color: '#fff' }} variant="h6" component="div">
              {Text.navigation.createSoundboard}
            </Typography>
          </Toolbar>
          <div className={styles.container}>
            <div className={styles.form}>
              <div className={styles.contentWrapper}>
                <General
                  handleChange={handleChange}
                  errors={errors}
                  values={values}
                  handleSoundboardImageUpload={handleSoundboardImageUpload}
                />
              </div>
              <div className={styles.contentWrapper}>
                <BindApplications
                  allApps={allApps}
                  selectedApps={selectedApps}
                  handleChangeApps={handleChangeApps}
                  handleDeleteApp={handleDeleteApp}
                />
              </div>
              <div className={styles.contentWrapper}>
                {styngs && (
                  <Categories
                    styngs={styngs}
                    categoriesList={categoriesList}
                    addNewCategory={addNewCategory}
                    removeCategory={removeCategory}
                    addStyngToCategory={addStyngToCategory}
                    setExpirationDate={setExpirationDate}
                    removeStyngFromCategory={removeStyngFromCategory}
                    styngsStore={styngsStore}
                    styngsPagesCount={styngsPagesCount}
                    handleStyngsChangePage={handleStyngsChangePage}
                  />
                )}
              </div>
            </div>

            <div className={styles.submitContainer}>
              <Button
                data-test="cancel-button"
                variant={ButtonVariant.OUTLINED}
                onClick={() => navigationStore.goToPage(SoundboardRoute.path)}
              >
                {Text.common.cancel}
              </Button>
              <Button data-test="submit-button" onClick={formik.handleSubmit}>
                {Text.common.save}
              </Button>
            </div>
          </div>

          {showConfirmationModal && (
            <ConfirmationModal
              open={showConfirmationModal}
              headerContent={'Please confirm'}
              handleClose={handleCloseConfirmationModal}
              text={'One or more styngs expire in less than seven days. Do you want to proceed?'}
              handleSubmit={handleSubmitValues}
            />
          )}
        </React.Fragment>
      )}
    </Box>
  )
}

export default thread(CreateSoundboard, [observer])
