/* eslint-disable react/jsx-sort-props */
import { useState, useEffect } from 'react'

import Select from 'ui/Select/Select'
import TextField from 'ui/TextField/TextField'

import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank'
import CheckBoxIcon from '@mui/icons-material/CheckBox'

import { Box, Chip, FormControl, Autocomplete, TextField as MuiTextField, Checkbox } from '@mui/material'

import Text from '../../locale/strings'
import { FilterFields } from 'common/constants'
import { Territory } from 'common/api/territory/territory'

import { useTerritoriesQuery } from 'territories/hooks'

import { OptionType } from 'common/api/common/common'

import styles from './SectionFilters.module.scss'

interface SectionFiltersProps {
  sectionStore: any
  filters: string[]
}

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />
const checkedIcon = <CheckBoxIcon fontSize="small" />

const publishFilterOptions: OptionType[] = [
  { label: 'All', value: 'null' },
  { label: 'Yes', value: 'true' },
  { label: 'No', value: 'false' },
]

const typeFilterOptions: OptionType[] = [
  { label: 'All', value: 'null' },
  { label: 'Licensed', value: 'LICENSED' },
  { label: 'Royalty-free', value: 'ROYALTY_FREE' },
]

const SectionFilters = ({ sectionStore, filters }: SectionFiltersProps) => {
  const { data: territories, status: territoriesStatus } = useTerritoriesQuery()

  const [idFilter, setIdFilter] = useState<string>('')
  const [debouncedCompanyIdValue, setDebouncedCompanyIdValue] = useState<string>('')
  const [nameFilter, setNameFilter] = useState<string>('')
  const [ageRestrictionFilter, setAgeRestrictionFilter] = useState<string>('')
  const [debouncedNameValue, setDebouncedNameValue] = useState<string>('')
  const [debouncedAgeRestrictionValue, setDebouncedAgeRestrictionValue] = useState<string>('')
  const [appFilter, setAppFilter] = useState<string>('')
  const [debouncedAppValue, setDebouncedAppValue] = useState<string>('')
  const [publishFilter, setPublishFilter] = useState<boolean | null>(null)
  const [typeFilter, setTypeFilter] = useState<string>('')

  const [allTerritories, setAllTerritories] = useState<Territory[]>([])
  const [selectedTerritories, setSelectedTerritories] = useState<Territory[]>([])

  // ID FLOW
  const handleIdFilter = (value: string) => {
    setIdFilter(value)
  }

  useEffect(() => {
    const timeoutCompanyId = setTimeout(() => {
      setDebouncedCompanyIdValue(idFilter)
    }, 500)

    return () => clearTimeout(timeoutCompanyId)
  }, [idFilter])

  useEffect(() => {
    if (sectionStore.handleIdSearch) {
      sectionStore.handleIdSearch(debouncedCompanyIdValue)
    }
  }, [debouncedCompanyIdValue])

  // NAME FLOW
  const handleNameFilter = (value: string) => {
    setNameFilter(value)
  }

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setDebouncedNameValue(nameFilter)
    }, 500)

    return () => clearTimeout(timeoutId)
  }, [nameFilter])

  useEffect(() => {
    if (sectionStore.handleNameSearch) {
      sectionStore?.handleNameSearch(debouncedNameValue)
    }
  }, [debouncedNameValue])

  // AGE RESTRICTION FLOW
  const handleAgeRestrictionFilter = (value: string) => {
    setAgeRestrictionFilter(value)
  }

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setDebouncedAgeRestrictionValue(ageRestrictionFilter)
    }, 500)

    return () => clearTimeout(timeoutId)
  }, [ageRestrictionFilter])

  useEffect(() => {
    if (sectionStore.handleAgeRegistrationSearch) {
      sectionStore?.handleAgeRegistrationSearch(debouncedAgeRestrictionValue)
    }
  }, [debouncedAgeRestrictionValue])

  // AVAILABILITY FLOW
  useEffect(() => {
    if (territories?.territories) {
      setAllTerritories(territories?.territories)
    }
  }, [territories])

  const handleChangeTerritories = (e: React.SyntheticEvent, value: Territory[]) => {
    setSelectedTerritories(value)
  }

  const handleDeleteTerritory = (value: any) => {
    setSelectedTerritories((territoryArray) =>
      territoryArray.filter((territoryOption) => territoryOption.territoryCode !== value),
    )
  }

  useEffect(() => {
    if (sectionStore.handleTerritorySearch) {
      sectionStore?.handleTerritorySearch(selectedTerritories.map((territory) => territory.territoryCode))
    }
  }, [selectedTerritories])

  // APP FLOW
  const handleAppFilter = (value: string) => {
    setAppFilter(value)
  }

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setDebouncedAppValue(appFilter)
    }, 500)

    return () => clearTimeout(timeoutId)
  }, [appFilter])

  useEffect(() => {
    if (sectionStore.handleAppSearch) {
      sectionStore.handleAppSearch(debouncedAppValue)
    }
  }, [debouncedAppValue])

  // PUBLISH FLOW
  const handlePublishFilter = (value: any) => {
    if (value === 'null') {
      setPublishFilter(null)
    } else if (value === 'true') {
      setPublishFilter(true)
    } else {
      setPublishFilter(false)
    }
  }

  useEffect(() => {
    if (sectionStore.handlePublishSearch) {
      sectionStore?.handlePublishSearch(publishFilter)
    }
  }, [publishFilter])

  // TYPE FLOW
  const handleTypeFilter = (value: string) => {
    setTypeFilter(value)
  }

  useEffect(() => {
    if (sectionStore.handleTypeSearch) {
      sectionStore?.handleTypeSearch(typeFilter)
    }
  }, [typeFilter])

  return (
    <div className={styles.filtersWrapper}>
      {filters.includes(FilterFields.ID) && (
        <div className={styles.filter}>
          <h5 className={styles.label}>{'Id'}</h5>
          <TextField
            data-test="filter-id"
            autoComplete="off"
            placeholder={Text.fields.inputPlaceholder}
            name="id"
            value={idFilter}
            className={styles.customInput}
            onChange={(e) => handleIdFilter(e.target.value)}
          />
        </div>
      )}
      {filters.includes(FilterFields.NAME) && (
        <div className={styles.filter}>
          <h5 className={styles.label}>{'Name'}</h5>
          <TextField
            data-test="filter-name"
            autoComplete="off"
            placeholder={Text.fields.inputPlaceholder}
            name="name"
            value={nameFilter}
            className={styles.customInput}
            onChange={(e) => handleNameFilter(e.target.value)}
          />
        </div>
      )}
      {filters.includes(FilterFields.AGE_RESTRICTION) && (
        <div className={styles.filter}>
          <h5 className={styles.label}>{'Age restriction'}</h5>
          <TextField
            data-test="filter-age-restriction"
            autoComplete="off"
            placeholder={Text.fields.inputPlaceholder}
            name="ageRestriction"
            value={ageRestrictionFilter}
            className={styles.customInput}
            onChange={(e) => handleAgeRestrictionFilter(e.target.value)}
          />
        </div>
      )}
      {filters.includes(FilterFields.AVAILABILITY) && territoriesStatus === 'success' && (
        <div className={styles.filter}>
          <h5 className={styles.label}>{'Availability'}</h5>
          <FormControl fullWidth className={styles.customAutocomplete}>
            <div className={styles.autocompleteWrapper}>
              <Autocomplete
                data-test="filter-availability"
                multiple
                disableCloseOnSelect
                id="availableCountries"
                className={styles.autocompleteField}
                isOptionEqualToValue={(option, value) => option.territoryCode === value.territoryCode}
                value={selectedTerritories}
                options={allTerritories}
                getOptionLabel={(option: Territory) => option.territoryName}
                renderOption={(props, option, { selected }) => (
                  <li {...props}>
                    <Checkbox icon={icon} checkedIcon={checkedIcon} style={{ marginRight: 8 }} checked={selected} />
                    {option.territoryName}
                  </li>
                )}
                renderTags={() => null}
                renderInput={(params) => <MuiTextField {...params} placeholder="Search" />}
                onChange={handleChangeTerritories}
              />
              {selectedTerritories.length > 0 && (
                <Box className={styles.autocompleteFieldValues}>
                  {selectedTerritories.map((option) => (
                    <Chip
                      className={styles.autocompleteFieldValue}
                      key={option.territoryCode}
                      label={option.territoryName}
                      onDelete={() => handleDeleteTerritory(option.territoryCode)}
                    />
                  ))}
                </Box>
              )}
            </div>
          </FormControl>
        </div>
      )}
      {filters.includes(FilterFields.APPLICATION) && (
        <div className={styles.filter}>
          <h5 className={styles.label}>{'Application'}</h5>
          <TextField
            data-test="filter-application"
            autoComplete="off"
            placeholder={Text.fields.inputPlaceholder}
            name="application"
            value={appFilter}
            className={styles.customInput}
            onChange={(e) => handleAppFilter(e.target.value)}
          />
        </div>
      )}
      {filters.includes(FilterFields.PUBLISHED) && (
        <div className={styles.filter}>
          <h5 className={styles.label}>{'Published'}</h5>
          <Select
            data-test="filter-published"
            fullWidth
            value={publishFilter === null ? 'null' : publishFilter.toString()}
            options={publishFilterOptions}
            className={styles.customSelect}
            onChange={(e) => handlePublishFilter(e)}
          />
        </div>
      )}
      {filters.includes(FilterFields.TYPE) && (
        <div className={styles.filter}>
          <h5 className={styles.label}>{'Type'}</h5>
          <Select
            data-test="filter-type"
            fullWidth
            value={typeFilter === '' ? 'null' : typeFilter.toString()}
            options={typeFilterOptions}
            className={styles.customSelect}
            onChange={(e) => handleTypeFilter(e)}
          />
        </div>
      )}
    </div>
  )
}

export default SectionFilters
