// import React from 'react'
import Modal from '@mui/material/Modal'
import Box from '@mui/material/Box'
import Button, { ButtonColor, ButtonVariant } from '../../ui/Button/Button'
import Text from '../../locale/strings'
import React from 'react'

import styles from './deleteModal.module.scss'

interface ModalProps {
  open: boolean
  text: string
  headerContent: string
  handleClose: () => void
  handleSubmit: () => void
}

const DeleteModal = ({ open, text, headerContent, handleClose, handleSubmit }: ModalProps) => {
  return (
    <div className={styles.holder}>
      <Modal open={open} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
        <Box>
          <div className={styles.container}>
            <div className={styles.header}>{headerContent}</div>
            <div className={styles.text}>{text}</div>
            <div className={styles.submitContainer}>
              <Button data-test="cancel-button" variant={ButtonVariant.OUTLINED} onClick={handleClose}>
                {Text.common.cancel}
              </Button>
              <Button data-test="submit-button" color={ButtonColor.PRIMARY} onClick={handleSubmit}>
                {Text.common.delete}
              </Button>
            </div>
          </div>
        </Box>
      </Modal>
    </div>
  )
}

export default DeleteModal
