/* eslint-disable jsx-control-statements/jsx-for-require-each */
import React, { useState } from 'react'
import * as R from 'ramda'
import { Duration } from 'luxon'

import Collapse from '@mui/material/Collapse'
import Chip from '../../ui/Chip/Chip'
import { JsonValidationIcon } from 'ui/Table/Row/images'

import IconButton from '@mui/material/IconButton'
import DeleteIcon from '@mui/icons-material/Delete'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp'
import JsonDataModal from '../../ui/JsonDataModal/JsonDataModal'
import DeleteModal from '../../ui/DeleteModal/DeleteModal'

import { Ad } from '../../common/api/radioAdGroups/radioAdGroups'
import { JsonMetadataModalDataTypes } from '../../common/constants'

import styles from './adItemWithMoreInfo.module.scss'

interface AdItemWithMoreInfoProps {
  ad: Ad
  onDelete: (id: string) => void
  adGroupId?: string
  refetch?: any
}

const AdItemWithMoreInfo = ({ ad, onDelete, adGroupId, refetch }: AdItemWithMoreInfoProps) => {
  const [open, setOpen] = useState<boolean>(false)
  const [jsonDataModal, setJsonDataModal] = useState<boolean>(false)
  const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false)

  const handleDeleteClick = () => {
    setShowDeleteModal(true)
  }

  const handleCloseDeleteModal = () => {
    setShowDeleteModal(false)
  }

  const handleDelete = () => {
    onDelete(ad.basicInfo.id)
  }

  const handleOpenJsonModal = () => {
    setJsonDataModal(true)
  }

  const handleCloseJsonModal = () => {
    setJsonDataModal(false)
  }

  const renderCountures = () => {
    return (
      <For
        of={ad.availability}
        body={(countryCode: string, index) => <Chip key={index} label={countryCode} className={styles.chips} />}
      />
    )
  }

  return (
    <div className={styles.container}>
      <IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
        {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
      </IconButton>
      <div className={styles.nameHolder}>
        <h3>{ad.basicInfo.name}</h3>
      </div>
      <DeleteIcon className={styles.deleteIcon} color={'action'} onClick={handleDeleteClick} />
      <div className={styles.svgIcon} onClick={() => handleOpenJsonModal()}>
        <JsonValidationIcon rule={!R.isNil(ad.metadata)} />
      </div>
      <Collapse unmountOnExit className={styles.collapse} timeout="auto" in={open}>
        <div className={styles.additionalInfo}>
          {renderCountures()}
          <p className={styles.Duration}>{Duration.fromISO(ad.basicInfo.duration).toFormat('mm:ss')}</p>
        </div>
      </Collapse>

      {jsonDataModal && (
        <JsonDataModal
          appId={adGroupId}
          dataTypeId={ad.basicInfo?.id}
          data={ad?.metadata}
          dataType={JsonMetadataModalDataTypes.AD}
          open={jsonDataModal}
          refetch={refetch}
          handleClose={handleCloseJsonModal}
        />
      )}

      {showDeleteModal && (
        <DeleteModal
          open={showDeleteModal}
          headerContent={'Remove ad from ad group'}
          handleClose={handleCloseDeleteModal}
          text={'Are you sure you want to permanently delete this ad?'}
          handleSubmit={handleDelete}
        />
      )}
    </div>
  )
}

export default React.memo(AdItemWithMoreInfo)
