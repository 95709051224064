import { useQuery } from 'react-query'

import { StynglistsResponse, EditStynglistEntity } from '../common/api/stynglist/stynglist'
import PaginationStore from '../common/PaginationStore'
import useStores from '../common/hook/useStore'
import API from '../common/api'
import * as R from 'ramda'

class StynglistsStore extends PaginationStore {}

export const useStynglistsQuery = () => {
  const { stynglistsStore } = useStores()

  const { pageSize, page, filter } = stynglistsStore

  return useQuery<StynglistsResponse>(
    ['stynglists', pageSize, page, filter],
    () => {
      return API.stynglist.list({
        page,
        size: pageSize,
        name: filter,
      })
    },
    {
      retry: 1,
      keepPreviousData: true,
      refetchOnWindowFocus: false,
    },
  )
}

export const useStynglistsAppQuery = (appId: Nullable<string> = null) => {
  const { stynglistsStore } = useStores()

  const { pageSize, page } = stynglistsStore

  return useQuery<StynglistsResponse>(
    ['stynglistsApp', appId],
    () => {
      return API.stynglist.list({
        page,
        size: pageSize,
        name: '',
        appId,
      })
    },
    {
      retry: 1,
      keepPreviousData: true,
      refetchOnWindowFocus: false,
    },
  )
}

export const useStynglistQuery = (id: string) => {
  return useQuery<EditStynglistEntity>(
    ['stynglist', id],
    () => {
      return (
        API.stynglist.get({
          id,
        }) ?? []
      )
    },
    {
      refetchOnWindowFocus: false,
      enabled: !R.isEmpty(id),
      keepPreviousData: false,
      retry: 1,
    },
  )
}

export default StynglistsStore
