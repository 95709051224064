import React, { useEffect, useState } from 'react'
import * as R from 'ramda'
import { observer } from 'mobx-react'

import Text from '../../locale/strings'
import SearchSelect from '../../ui/SearchSelect/SearchSelect'
import TracksForStyngsStore from '../../tracksForStyngs/TracksForStyngsStore'
import TrackFilterChips from '../../tracks/TrackFilterChips/TrackFilterChips'
import {
  useAlbumsQuery,
  useArtistsMutation,
  useGenresQuery,
  useLabelsQuery,
  useLabelOwnersQuery,
} from '../../tracks/filterHooks'
import { Artist, toArtist } from '../../common/api/artist/artist'
import { Genre } from '../../common/api/genre/genre'
import { Album } from '../../common/api/album/album'
import { Country } from '../../common/api/country/country'
import { countries } from '../../common/countries'
import { Label } from '../../common/api/label/label'
import { LabelOwner } from '../../common/api/labelOwner/labelOwner'

import styles from './trackFilter.module.scss'
// import { Identity } from 'common/api/common/common'

interface TrackFilterProps {
  tracksStore: TracksForStyngsStore
}

const toGenreOptions = (genre: Genre) => ({
  label: genre.name,
  value: genre,
})

const toArtistOptions = (artist: Artist) => ({
  label: artist.name,
  value: artist,
})

const toAlbumOptions = (album: Album) => ({
  label: album.name,
  value: album,
})

const toLabelOptions = (label: Label) => ({
  label: label.name,
  value: label,
})

const toLabelOwnerOptions = (labelOwner: LabelOwner) => ({
  label: labelOwner.name,
  value: labelOwner,
})

const toCountryOptions = (country: Country) => ({
  label: country.name,
  value: country,
})

const countryOptions = R.map(toCountryOptions, countries ?? [])

const TrackFilterBar = ({ tracksStore }: TrackFilterProps) => {
  const [artistFilter, setArtistFilter] = useState('')
  const [albumFilter, setAlbumFilter] = useState('')
  const [labelFilter, setLabelFilter] = useState('')
  const [labelOwnerFilter, setLabelOwnerFilter] = useState('')
  const [artists, setArtists] = useState<Artist[]>([])
  const artistMutation = useArtistsMutation()
  const { data: genresData } = useGenresQuery()
  const { data: albumsData } = useAlbumsQuery(tracksStore, albumFilter)
  const { data: labelsData } = useLabelsQuery(tracksStore, labelFilter)
  const { data: labelOwnersData } = useLabelOwnersQuery(tracksStore, labelOwnerFilter)

  const genreOptions = R.map(toGenreOptions, genresData?.genres ?? [])
  const artistOptions = R.map(toArtistOptions, artists ?? [])
  const albumOptions = R.map(toAlbumOptions, albumsData ?? [])
  const labelOptions = R.map(toLabelOptions, labelsData ?? [])
  const labelOwnersOptions = R.map(toLabelOwnerOptions, labelOwnersData ?? [])

  useEffect(() => {
    artistMutation.mutate(
      {
        name: artistFilter,
      },
      {
        onSuccess: (res) => {
          setArtists(R.map(toArtist, res.artists))
        },
      },
    )
  }, [artistFilter])

  const handleChangeInputArtist = (value: string) => {
    setArtistFilter(value)
  }

  const handleChangeInputAlbum = (value: string) => {
    setAlbumFilter(value)
  }

  const handleChangeInputLabel = (value: string) => {
    setLabelFilter(value)
  }

  const handleChangeInputLabelOwner = (value: string) => {
    setLabelOwnerFilter(value)
  }

  const handleChangeArtists = (values: Artist[]) => {
    tracksStore.addArtist(values[0])
  }

  const handleChangeAlbums = (values: Album[]) => {
    tracksStore.addAlbum(values[0])
  }

  const handleChangeGenres = (values: Genre[]) => {
    tracksStore.addGenre(values[0])
  }

  const handleChangeCountries = (values: Country[]) => {
    tracksStore.addCountry(values[0])
  }

  const handleChangeLabels = (values: Label[]) => {
    tracksStore.addLabel(values[0])
  }

  const handleChangeLabelOwner = (values: LabelOwner[]) => {
    tracksStore.addLabelOwner(values[0])
  }

  return (
    <div className={styles.container}>
      <TrackFilterChips tracksStore={tracksStore} />
      <div className={styles.selects}>
        <SearchSelect<Artist>
          className={styles.select}
          label={Text.fields.artists}
          value={tracksStore.activeArtists}
          options={artistOptions}
          onChange={handleChangeArtists}
          onInputChange={handleChangeInputArtist}
        />
        <SearchSelect<Album>
          className={styles.select}
          label={Text.fields.albums}
          value={tracksStore.activeAlbums}
          options={albumOptions}
          onChange={handleChangeAlbums}
          onInputChange={handleChangeInputAlbum}
        />
        <SearchSelect<Genre>
          className={styles.select}
          label={Text.fields.genres}
          value={tracksStore.activeGenres}
          options={genreOptions}
          onChange={handleChangeGenres}
        />
        <SearchSelect<Country>
          className={styles.select}
          label={Text.fields.countries}
          value={tracksStore.activeCountries}
          options={countryOptions}
          onChange={handleChangeCountries}
        />
        <SearchSelect<Label>
          className={styles.select}
          label={Text.fields.labels}
          value={tracksStore.activeLabels}
          options={labelOptions}
          onChange={handleChangeLabels}
          onInputChange={handleChangeInputLabel}
        />
        <SearchSelect<LabelOwner>
          className={styles.select}
          label={Text.fields.labelOwner}
          value={tracksStore.activeLabels}
          options={labelOwnersOptions}
          onChange={handleChangeLabelOwner}
          onInputChange={handleChangeInputLabelOwner}
        />
      </div>
    </div>
  )
}

export default observer(TrackFilterBar)
