import { observer } from 'mobx-react'
import { thread } from 'lib/fn/thread'

import StynglistsTable from '../pages/StynglistsTable/StynglistsTable'
import { useStynglistsQuery } from '../../stynglists/StynglistsStore'
import LoadingRemote from '../../common/LoadingRemote'

const StynglistsPage = () => {
  const { data, refetch, status } = useStynglistsQuery()

  const stynglists = data?.items ?? []
  const pageCount = data?.pagesCount ?? 1

  return (
    <LoadingRemote status={status}>
      <StynglistsTable stynglists={stynglists} refetch={refetch} pagesCount={pageCount} />
    </LoadingRemote>
  )
}

export default thread(StynglistsPage, [observer])
