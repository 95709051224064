import Route from 'lib/routing/Route'
import strings from '../../locale/strings'
import StyngsPage from './StyngsPage'
// import CreateStyngPage from './CreateStyngPage'
// import EditStyngPage from './EditStyngPage'

export const StyngsRoute: Route = {
  path: '/styngs',
  name: strings.navigation.styngs,
  exact: true,
  component: StyngsPage,
}

// export const CreateStyngRoute: Route = {
//   path: '/styngs/create',
//   name: strings.navigation.createStyng,
//   exact: true,
//   component: CreateStyngPage,
// }

// export const EditStyngRoute: Route = {
//   path: '/styngs/edit/:styngId',
//   name: strings.navigation.createStyng,
//   exact: true,
//   component: EditStyngPage,
// }
