import React, { useEffect } from 'react'
import * as R from 'ramda'
import { observer } from 'mobx-react'
import { useFormik } from 'formik'
import { useMutation, useQueryClient } from 'react-query'

import Text from '../../locale/strings'
import Button from '../../ui/Button/Button'
import useStores from '../../common/hook/useStore'
import TextField from '../../ui/TextField/TextField'
import API from '../../common/api'
import { EditAppEventEntity, EditAppEventRequest, EditAppEventResponse } from '../../common/api/appEvent/appEvent'
import { AppEventsRoute } from '../../appEvent/pages'

import styles from './editAppEvent.module.scss'

interface AppEventFormValues {
  name: string
  description: string
}

const initialValues: AppEventFormValues = {
  name: '',
  description: '',
}

interface EditAppEventProps {
  initialAppEvent?: EditAppEventEntity
}

const EditAppEvent = ({ initialAppEvent }: EditAppEventProps) => {
  const { navigationStore, applicationStore } = useStores()
  const queryClient = useQueryClient()

  const mutationAppEvent = useMutation<EditAppEventResponse, Error, EditAppEventRequest>(
    (body) => {
      if (!R.isNil(initialAppEvent)) {
        return API.event.put({ ...body, id: initialAppEvent.id })
      }

      return API.event.create(body)
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries('styngs')
        navigationStore.goToPage(AppEventsRoute.path)
      },
    },
  )

  const handleSubmit = async (values: AppEventFormValues) => {
    if (!R.isEmpty(values.name) && !R.isEmpty(values.description)) {
      const body = {
        name: values.name,
        description: values.description,
        appId: applicationStore.appId,
      }

      mutationAppEvent.mutate(body)
    }
  }

  const formik = useFormik<AppEventFormValues>({
    initialValues: initialValues,
    onSubmit: handleSubmit,
  })

  const { values, resetForm } = formik

  useEffect(() => {
    if (!R.isNil(initialAppEvent)) {
      resetForm({
        values: {
          name: initialAppEvent.name,
          description: initialAppEvent.description,
        },
      })
    }
  }, [initialAppEvent, resetForm])

  const handleCancel = () => {
    navigationStore.goToPage(AppEventsRoute.path)
  }

  return (
    <div className={styles.container}>
      <div className={styles.form}>
        <TextField
          label={Text.page.appEvents.create.fields.name}
          name="name"
          value={values.name}
          onChange={formik.handleChange}
        />
        <TextField
          label={Text.page.appEvents.create.fields.description}
          name="description"
          value={values.description}
          className={styles.input}
          onChange={formik.handleChange}
        />
        <div className={styles.submitContainer}>
          <Button onClick={handleCancel}>{Text.common.cancel}</Button>
          <Button loading={mutationAppEvent.isLoading} onClick={formik.handleSubmit}>
            {Text.common.save}
          </Button>
        </div>
      </div>
    </div>
  )
}

export default observer(EditAppEvent)
