import { useState, useEffect } from 'react'
import * as R from 'ramda'
import { observer } from 'mobx-react'
import useDebounce from '../../common/hook/useDebounce'

import Text from '../../locale/strings'
import SearchSelect from '../../ui/SearchSelect/SearchSelect'
import Tags from '../../ui/Tags/Tags'
import Select from '../../ui/Select/Select'

import TracksStore from '../../tracks/TracksStore'
import TrackFilterChips from '../../tracks/TrackFilterChips/TrackFilterChips'
import {
  useAlbumsQuery,
  useArtistsMutation,
  useGenresQuery,
  useLabelsQuery,
  useLabelOwnersQuery,
} from '../../tracks/filterHooks'
import { Genre } from '../../common/api/genre/genre'
import { Artist, toArtist } from '../../common/api/artist/artist'
import { Album } from '../../common/api/album/album'
import { Country } from '../../common/api/country/country'
import { countries } from '../../common/countries'
import { Label } from '../../common/api/label/label'
import { LabelOwner } from '../../common/api/labelOwner/labelOwner'
import { OptionType } from '../../common/api/common/common'

import styles from './trackFilter.module.scss'

interface TrackFilterProps {
  tracksStore: TracksStore
}

const toGenreOptions = (genre: Genre) => ({
  label: genre.name,
  value: genre,
})

const toArtistOptions = (artist: Artist) => ({
  label: artist.name,
  value: artist,
})

const toAlbumOptions = (album: Album) => ({
  label: album.name,
  value: album,
})

const toLabelOptions = (label: Label) => ({
  label: label.name,
  value: label,
})

const toLabelOwnerOptions = (labelOwner: LabelOwner) => ({
  label: labelOwner.name,
  value: labelOwner,
})

const toCountryOptions = (country: Country) => ({
  label: country.name,
  value: country,
})

const countryOptions = R.map(toCountryOptions, countries ?? [])

const parentalAdvisoryOptions: OptionType[] = [
  { label: 'All', value: 'null' },
  { label: 'Yes', value: 'true' },
  { label: 'No', value: 'false' },
]

const TrackFilterBar = ({ tracksStore }: TrackFilterProps) => {
  const [artistFilter, setArtistFilter] = useState('')
  const [albumFilter, setAlbumFilter] = useState('')
  const [labelFilter, setLabelFilter] = useState('')
  const [labelOwnerFilter, setLabelOwnerFilter] = useState('')
  const [isrcFilter, setIsrcFilter] = useState<any[]>([])
  const [mediaNetID, setMediaNetID] = useState<any[]>([])
  const [parentalAdvisory, setParentalAdvisory] = useState<boolean | null>(null)
  const [artists, setArtists] = useState<Artist[]>([])
  const { data: genresData } = useGenresQuery()
  const artistMutation = useArtistsMutation()

  const genreOptions = R.map(toGenreOptions, genresData?.genres ?? [])
  const artistOptions = R.map(toArtistOptions, artists ?? [])

  const debouncedAlbumsSearch = useDebounce(albumFilter, 1000)
  const { data: albumsData } = useAlbumsQuery(tracksStore, debouncedAlbumsSearch)
  const albumOptions = R.map(toAlbumOptions, albumsData ?? [])

  const debouncedLabelSearch = useDebounce(labelFilter, 1000)
  const { data: labelsData } = useLabelsQuery(tracksStore, debouncedLabelSearch)
  const labelOptions = R.map(toLabelOptions, labelsData ?? [])

  const debouncedLabeOwnerlSearch = useDebounce(labelOwnerFilter, 1000)
  const { data: labelOwnersData } = useLabelOwnersQuery(tracksStore, debouncedLabeOwnerlSearch)
  const labelOwnersOptions = R.map(toLabelOwnerOptions, labelOwnersData ?? [])

  const debouncedArtistSearch = useDebounce(artistFilter, 1000)

  useEffect(() => {
    artistMutation.mutate(
      {
        name: debouncedArtistSearch,
      },
      {
        onSuccess: (res) => {
          setArtists(R.map(toArtist, res.artists))
        },
      },
    )
  }, [debouncedArtistSearch])

  const handleChangeInputArtist = (value: string) => {
    setArtistFilter(value)
  }

  const handleChangeInputAlbum = (value: string) => {
    setAlbumFilter(value)
  }

  const handleChangeInputLabel = (value: string) => {
    setLabelFilter(value)
  }

  const handleChangeInputLabelOwner = (value: string) => {
    setLabelOwnerFilter(value)
  }

  const handleChangeArtists = (values: Artist[]) => {
    tracksStore.addArtist(values[0])
  }

  const handleChangeAlbums = (values: Album[]) => {
    tracksStore.addAlbum(values[0])
  }

  const handleChangeGenres = (values: Genre[]) => {
    tracksStore.addGenre(values[0])
  }

  const handleChangeCountries = (values: Country[]) => {
    tracksStore.addCountry(values[0])
  }

  const handleChangeLabels = (values: Label[]) => {
    tracksStore.addLabel(values[0])
  }

  const handleChangeLabelOwner = (values: LabelOwner[]) => {
    tracksStore.addLabelOwner(values[0])
  }

  const handleChangeIsrcFilter = (value: any[]) => {
    setIsrcFilter(value)
  }

  const handleChangeMediaNetID = (value: any[]) => {
    setMediaNetID(value)
  }

  const handleChangeParentalAdvisory = (value: any) => {
    if (value === 'null') {
      setParentalAdvisory(null)
    } else if (value === 'true') {
      setParentalAdvisory(true)
    } else {
      setParentalAdvisory(false)
    }
  }

  useEffect(() => {
    tracksStore.handleIsrcSearch(isrcFilter)
  }, [isrcFilter])

  useEffect(() => {
    tracksStore.handleMediaNetIDSearch(mediaNetID)
  }, [mediaNetID])

  useEffect(() => {
    tracksStore.setParentalAdvisory(parentalAdvisory)
  }, [parentalAdvisory])

  return (
    <div className={styles.container}>
      <TrackFilterChips tracksStore={tracksStore} />
      <div className={styles.selects}>
        <SearchSelect<Artist>
          className={styles.select}
          label={Text.fields.artists}
          value={tracksStore.activeArtists}
          options={artistOptions}
          onChange={handleChangeArtists}
          onInputChange={handleChangeInputArtist}
        />
        <SearchSelect<Album>
          className={styles.select}
          label={Text.fields.albums}
          value={tracksStore.activeAlbums}
          options={albumOptions}
          onChange={handleChangeAlbums}
          onInputChange={handleChangeInputAlbum}
        />

        <SearchSelect<Genre>
          className={styles.select}
          label={Text.fields.genres}
          value={tracksStore.activeGenres}
          options={genreOptions}
          onChange={handleChangeGenres}
        />
        <SearchSelect<Country>
          className={styles.select}
          label={Text.fields.countries}
          value={tracksStore.activeCountries}
          options={countryOptions}
          onChange={handleChangeCountries}
        />
        <SearchSelect<Label>
          className={styles.select}
          label={Text.fields.labels}
          value={tracksStore.activeLabels}
          options={labelOptions}
          onChange={handleChangeLabels}
          onInputChange={handleChangeInputLabel}
        />

        <SearchSelect<LabelOwner>
          className={styles.select}
          label={Text.fields.labelOwner}
          value={tracksStore.activeLabels}
          options={labelOwnersOptions}
          onChange={handleChangeLabelOwner}
          onInputChange={handleChangeInputLabelOwner}
        />

        <Tags
          wrapperClassNames={styles.tagStyle}
          label={Text.fields.isrc}
          handleTagsUpdate={handleChangeIsrcFilter}
          maxInputLength={19}
          titleTooltip={[
            'Entering duplicate values ​​is not allowed',
            'Maximum of 19 characters per value',
            'When pasting multiple values into the input field, separate them by a comma. (e.g. xxx, xxxxx, xxx)',
          ]}
        />

        <Tags
          wrapperClassNames={styles.tagStyle}
          label={Text.fields.mediaNetID}
          handleTagsUpdate={handleChangeMediaNetID}
          tagInputType={'number'}
          maxInputLength={19}
          titleTooltip={[
            'This input only accepts numbers',
            'Entering duplicate values ​​is not allowed',
            'Maximum of 19 characters per value',
            'When pasting multiple values into the input field, separate them by a comma. (e.g. xxx, xxxxx, xxx)',
          ]}
        />

        <div className={styles.selectWrapper}>
          <h5 className={styles.label}>{Text.fields.parentalAdvisory}</h5>
          <Select
            fullWidth
            value={tracksStore.parentalAdvisory === null ? 'null' : tracksStore.parentalAdvisory.toString()}
            options={parentalAdvisoryOptions}
            className={styles.customSelect}
            onChange={(e) => handleChangeParentalAdvisory(e)}
          />
        </div>
      </div>
    </div>
  )
}

export default observer(TrackFilterBar)
