/* eslint-disable react/jsx-sort-props */
import React, { useEffect, useState } from 'react'
import { useMutation } from 'react-query'

import useStores from '../../common/hook/useStore'

import LoadingButton from '@mui/lab/LoadingButton'
import Modal from '@mui/material/Modal'

import Text from '../../locale/strings'
import Button, { ButtonVariant, ButtonColor } from '../../ui/Button/Button'

import TextField from '../../ui/TextField/TextField'
import API from '../../common/api'
import { JsonMetadataModalDataTypes } from '../../common/constants'

import { UpdateMetadataRequest } from '../../common/api/styng/styng'
import { UpdateTrackExternalMetadataRequest, UpdateRoyaltyFreeTrackMetadataRequest } from '../../common/api/track/track'

import AddIcon from '@mui/icons-material/Add'
import DeleteIcon from '@mui/icons-material/Delete'
import styles from './jsonDataModal.module.scss'

interface JsonDataModalProps {
  open: boolean
  dataTypeId: string | undefined
  data: any
  dataType: string
  refetch: () => void
  handleClose: () => void
  appId?: string
}

const JsonDataModal: React.FC<JsonDataModalProps> = ({
  open,
  appId,
  dataTypeId,
  data,
  dataType,
  refetch,
  handleClose,
}) => {
  const { notificationsStore } = useStores()
  const [initialMetadataArray, setInitialMetadataArray] = useState<any>([])
  const [metadataArray, setMetadataArray] = useState<any>([])
  const [showErrorOnSave, setShowErrorOnSave] = useState<boolean>(false)
  const [saveDisabled, setSaveDisabled] = useState<boolean>(true)

  useEffect(() => {
    const itemMetadataJson = data ?? ''

    if (itemMetadataJson) {
      const metadataObject = JSON.parse(itemMetadataJson)
      const helperMetadataArray = []

      for (const key in metadataObject) {
        if (metadataObject.hasOwnProperty(key)) {
          helperMetadataArray.push({
            key: key,
            value: metadataObject[key],
          })
        }
      }

      setInitialMetadataArray([...helperMetadataArray])
      setMetadataArray([...helperMetadataArray])
    }
  }, [data])

  useEffect(() => {
    isSaveDisabled(initialMetadataArray, metadataArray)
  }, [metadataArray])

  const updateMetadataMutation = useMutation<string, Error, UpdateMetadataRequest>(
    (body) => {
      return API.styngs.updateMetadata(body)
    },
    {
      onSuccess: () => {
        notificationsStore.successNotification(Text.jsonDataModal.successText)
        refetch()
        handleClose()
      },
      onError: (error) => {
        const errorData = JSON.stringify(error)
        const parsedError = JSON.parse(errorData)

        notificationsStore.errorNotification(parsedError.errorMessage)
      },
    },
  )

  const updateExternalMetadataMutation = useMutation<string, Error, UpdateTrackExternalMetadataRequest>(
    (body) => {
      return API.track.updateTrackExternalMetadaData(body)
    },
    {
      onSuccess: () => {
        notificationsStore.successNotification(Text.jsonDataModal.successText)
        refetch()
        handleClose()
      },
      onError: (error) => {
        const errorData = JSON.stringify(error)
        const parsedError = JSON.parse(errorData)

        notificationsStore.errorNotification(parsedError.errorMessage)
      },
    },
  )

  const updateRoyaltyFreeExternalMetadataMutation = useMutation<string, Error, UpdateRoyaltyFreeTrackMetadataRequest>(
    (body) => {
      return API.track.updateRoyaltyFreeTrackExternalMetadaData(body)
    },
    {
      onSuccess: () => {
        notificationsStore.successNotification(Text.jsonDataModal.successText)
        refetch()
        handleClose()
      },
      onError: (error) => {
        const errorData = JSON.stringify(error)
        const parsedError = JSON.parse(errorData)

        notificationsStore.errorNotification(parsedError.errorMessage)
      },
    },
  )

  const updateAdMetadataMutation = useMutation<string, Error, any>(
    (body) => {
      return API.radioAds.updateMetadata(body)
    },
    {
      onSuccess: () => {
        notificationsStore.successNotification(Text.jsonDataModal.successText)
        refetch()
        handleClose()
      },
      onError: (error) => {
        const errorData = JSON.stringify(error)
        const parsedError = JSON.parse(errorData)

        notificationsStore.errorNotification(parsedError.errorMessage)
      },
    },
  )

  const isSaveDisabled = (initialArray: any[], currentArray: any[]) => {
    if (initialArray.length !== currentArray.length) {
      setSaveDisabled(false)

      return
    }

    for (let i = 0; i < initialArray.length; i++) {
      if (initialArray[i] !== currentArray[i]) {
        setSaveDisabled(false)

        return
      }
    }

    setSaveDisabled(true)
  }

  const onAddClick = () => {
    setMetadataArray([...metadataArray, { key: '', value: '' }])
  }

  const onDeleteClick = (key: any) => {
    setMetadataArray((metadataArray: any) =>
      metadataArray.filter((_item: { [key: string]: string | number }, index: number) => index !== key),
    )
  }

  const renderJsonRows = () => {
    return metadataArray.map((item: { [key: string]: string | number }, index: number) => (
      <div className={styles.rowContent} key={index}>
        <TextField
          className={styles.textField}
          label={'Key'}
          name="key"
          value={item.key}
          onChange={(e) => handleInputUpdate('key', e, index)}
        />
        <TextField
          className={styles.textField}
          label={'Value'}
          name="value"
          value={item.value}
          onChange={(e) => handleInputUpdate('value', e, index)}
        />
        <DeleteIcon color={'action'} className={styles.deleteIcon} onClick={() => onDeleteClick(index)} />
      </div>
    ))
  }

  const handleInputUpdate = (type: string, e: any, index: number) => {
    const newValue = e.target.value

    setMetadataArray((prevArray: any) =>
      prevArray.map((item: any, i: number) => (i === index ? { ...item, [type]: newValue } : item)),
    )
  }

  const handleSubmit = async () => {
    let jsonForSave: object

    for (const metadataObject of metadataArray) {
      if (metadataObject.hasOwnProperty('key') && metadataObject['key'] === '') {
        setShowErrorOnSave(true)

        return true
      }

      setShowErrorOnSave(false)
    }

    if (metadataArray.length < 1) {
      jsonForSave = {}
    } else {
      jsonForSave = metadataArray.reduce((acc: any, obj: any) => {
        acc[obj.key] = obj.value

        return acc
      }, {})
    }

    if (dataType === JsonMetadataModalDataTypes.STYNG) {
      const body = {
        styngId: dataTypeId,
        metadata: JSON.stringify(jsonForSave),
      }

      updateMetadataMutation.mutate(body)
    } else if (dataType === JsonMetadataModalDataTypes.TRACK) {
      const body = {
        appId: appId,
        trackMediaNetId: dataTypeId,
        metadata: JSON.stringify(jsonForSave),
      }

      updateExternalMetadataMutation.mutate(body)
    } else if (dataType === JsonMetadataModalDataTypes.ROYALTY_FREE_TRACK) {
      const body = {
        appId: appId,
        trackId: dataTypeId,
        metadata: JSON.stringify(jsonForSave),
      }

      updateRoyaltyFreeExternalMetadataMutation.mutate(body)
    } else if (dataType === JsonMetadataModalDataTypes.AD) {
      const body = {
        adId: dataTypeId,
        metadata: JSON.stringify(jsonForSave),
      }

      updateAdMetadataMutation.mutate(body)
    }
  }

  return (
    <React.Fragment>
      {<div className={styles.backdropDiv} />}
      <Modal hideBackdrop open={open} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
        <div className={styles.container}>
          <div className={styles.header}>{Text.jsonDataModal.enterMetadata}</div>
          <div className={styles.body}>
            <div className={styles.rowWrapper}>{renderJsonRows()}</div>
            <div className={styles.addNewwWrapper}>
              <div className={styles.addButtonRow}>
                <LoadingButton
                  size="small"
                  color="primary"
                  variant="outlined"
                  endIcon={<AddIcon />}
                  onClick={() => onAddClick()}
                >
                  <span>{Text.common.add}</span>
                </LoadingButton>
              </div>
            </div>
          </div>
          <div className={styles.submitContainer}>
            {showErrorOnSave && (
              <div className={styles.errorInfoBox}>
                <p>{Text.jsonDataModal.errorTextKeyIsMissing}</p>
              </div>
            )}
            <Button variant={ButtonVariant.OUTLINED} onClick={handleClose}>
              {Text.common.cancel}
            </Button>
            <Button
              disabled={saveDisabled}
              color={saveDisabled ? ButtonColor.INFO : ButtonColor.PRIMARY}
              onClick={handleSubmit}
            >
              {Text.common.save}
            </Button>
          </div>
        </div>
      </Modal>
    </React.Fragment>
  )
}

export default JsonDataModal
