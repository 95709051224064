/* eslint-disable jsx-control-statements/jsx-for-require-each */
import { useEffect, useState } from 'react'
import { observer } from 'mobx-react'
import { useMutation } from 'react-query'

import useStores from '../../../common/hook/useStore'
import { getCustomErrorNotificationByCode } from '../../../ui/Snackbar/SnackbarHelper'

import { RadioAdGroupsCreateRoute, RadioAdGroupsEditRoute } from '../..'

import { useAdGroupsListQuery } from '../../hooks'

import Table from '../../../ui/Table/Table'
import Pagination from '../../../ui/Pagination/Pagination'
import Button from '../../../ui/Button/Button'
import LoadingComponent from '../../../ui/Loading/LoadingPage'
import ErrorLoading from '../../../ui/ErrorLoading/ErrorLoading'

import Text from '../../../locale/strings'

import API from '../../../common/api'
import { ApiStatuses } from '../../../common/constants'
import { RadioAdGroup } from '../../../common/api/radioAdGroups/radioAdGroups'

import styles from './RadioAdGroupsTable.module.scss'

const INITIAL_PAGE = 1

const RadioAdGroupsTable = () => {
  const { adGroupsStore, navigationStore, notificationsStore } = useStores()
  const { data, status, refetch } = useAdGroupsListQuery()
  const [numberOfPages, setNumberOfPages] = useState<number>(INITIAL_PAGE)
  const adGroups = data?.adGroups ?? []

  const onDeleteMutation = useMutation<any, Error, any>(
    (body: any) => {
      return API.playlist.deleteAdGroup({ playlistId: body.playlistId, adGroupId: body.adGroupId })
    },
    {
      onSuccess: () => {
        refetch()
        notificationsStore.successNotification('Ad Group successfully deleted')
      },
      onError: (error: any) => {
        const errorObj = JSON.stringify(error)
        const parseObj = JSON.parse(errorObj)
        const errorCode = parseObj.errorCode
        const errorMessage = getCustomErrorNotificationByCode(errorCode)

        notificationsStore.errorNotification(errorMessage)
      },
    },
  )

  useEffect(() => {
    return () => {
      adGroupsStore.changePage(INITIAL_PAGE)
    }
  }, [])

  useEffect(() => {
    if (data?.pageCount) {
      setNumberOfPages(data.pageCount)
    }
  }, [data])

  const handleCreateAdGroup = () => {
    navigationStore.goToPage(RadioAdGroupsCreateRoute.path)
  }

  const handleEditClick = (adGroupId: string) => {
    const currentData: RadioAdGroup[] = data?.adGroups ?? []
    const adGroupObjForEdit: RadioAdGroup | undefined = currentData.find((adGroup) => adGroup.id === adGroupId)
    const playlistIdForEdit: string = adGroupObjForEdit?.playlistId ?? ''

    if (playlistIdForEdit !== '') {
      navigationStore.goToPage(RadioAdGroupsEditRoute.getUrl({ playlistId: playlistIdForEdit }))
    }
  }

  const columns = ['Id', 'Bound to Playlist', 'Total # of ads', 'Actions']

  const dataTable = adGroups.map((adGroup: RadioAdGroup) => [adGroup.id, adGroup.playlistName, adGroup.ads.length])

  const handleChangePage = (value: number) => {
    adGroupsStore.changePage(value)
  }

  const handleDeleteClick = (id: string) => {
    const adGroupObject = adGroups.find((adGroup: RadioAdGroup) => adGroup.id === id)

    const body = {
      playlistId: adGroupObject?.playlistId,
      adGroupId: id,
    }

    onDeleteMutation.mutate(body)
  }

  return (
    <Choose>
      <When condition={status === ApiStatuses.LOADING}>
        <LoadingComponent />
      </When>
      <When condition={status === ApiStatuses.SUCCESS}>
        <div className={styles.container}>
          <div className={styles.header}>
            <Button data-test="create-button" onClick={() => handleCreateAdGroup()}>
              {Text.radioAdGroups.create}
            </Button>
          </div>
          <Table columns={columns} data={dataTable} onEditClick={handleEditClick} onDeleteClick={handleDeleteClick} />
          {numberOfPages > 0 && (
            <div>
              <div className={styles.paginationContainer}>
                <div className={styles.pagination}>
                  <Pagination page={adGroupsStore.page} count={numberOfPages} onChange={handleChangePage} />
                </div>
              </div>
            </div>
          )}
        </div>
      </When>
      <When condition={status === ApiStatuses.ERROR}>
        <ErrorLoading />
      </When>
    </Choose>
  )
}

export default observer(RadioAdGroupsTable)
